import { useCallback } from 'react'
import { authFetch } from '../lib/authFetch'

export const useDeleteMe = () => {
  return useCallback(async () => {
    const result = await authFetch<{ success: boolean }>(
      process.env.REACT_APP_DELETE_URL,
      {
        method: 'DELETE',
      }
    )

    if (result.success) {
      window.location.href = '/'
    }

    return result
  }, [])
}
