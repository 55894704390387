import React from 'react'
import { Bar } from 'react-chartjs-2'

const HorizontalBarChart = ({
  pace,
  // energy,
  complexity,
  pronouciation,
  accuracy,
  completeness,
  fluency
}) => {
  const data = {
    // labels: ['Pace', 'Energy', 'Complexity', 'Pronouciation', 'Accuracy', 'Completeness', 'Fluency'],
    labels: ['Pace', 'Complexity', 'Pronouciation', 'Accuracy', 'Completeness', 'Fluency'],
    datasets: [
      {
        label: 'Score',
        // data: [pace, energy, complexity, pronouciation, accuracy, completeness, fluency],
        data: [pace, complexity, pronouciation, accuracy, completeness, fluency],
        backgroundColor: [
          '#ed7d31',
          // '#ffc000',
          '#fe85ce',
          '#5fd764',
          '#648ddd',
          '#b664dd',
          '#fe85ce',
        ],
        borderColor: [
          '#ed7d31',
          // '#ffc000',
          '#fe85ce',
          '#5fd764',
          '#648ddd',
          '#b664dd',
          '#fe85ce',
        ],
        borderWidth: 1
      }
    ]
  }
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
    },
  }

  return (
    <>
      <Bar data={data} options={options} />
    </>
  )
}

export default HorizontalBarChart