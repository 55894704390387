const readingLevelAndGrade: {
  grade: string
  maxLevel: number
  description: string
}[] = [
  {
    maxLevel: 30,
    grade: 'Beginner',
    description:
      'Your speech is veryeasy to understand. This is an ideal score that most people can understand.',
  },
  {
    maxLevel: 50,
    grade: 'Elementary',
    description:
      'Your speech is easy to understand. This is an ideal score that most people can understand.',
  },
  {
    maxLevel: 60,
    grade: 'Lower Intermediate',
    description:
      'Your speech is easy to understand. This is an ideal score that most people can understand.',
  },
  {
    maxLevel: 70,
    grade: 'Intermediate',
    description:
      'Your speech is fairly easy to understand. We recommend the higher the position you are applying for, this higher the score should be. If you are applying for entry roles, it is ideal to aim for score at least 60.',
  },
  {
    maxLevel: 80,
    grade: 'Upper Intermediate',
    description:
      'Your speech is estimated to be understood by most 10th to 12th grade students.',
  },
  {
    maxLevel: 90,
    grade: 'Advanced',
    description:
      'Your speech is estimated to be understood by most college students. We recommend the higher the position you are applying for, this higher the score should be. If you are applying for entry roles, it is ideal to aim for score at least 60.',
  },
  {
    maxLevel: 100,
    grade: 'Professional',
    description:
      'Your speech is estimated to be understood by most college graduate.',
  },
]

export const getGradeForReadingLevel = (readingLevel: number): string => {
  for (const { maxLevel, grade } of readingLevelAndGrade) {
    if (readingLevel < maxLevel) {
      return grade
    }
  }

  return (
    'Less than ' + readingLevelAndGrade[readingLevelAndGrade.length - 1].grade
  )
}

export const getDescriptionForReadingLevel = (readingLevel: number): string => {
  for (const { maxLevel, description } of readingLevelAndGrade) {
    if (readingLevel <= maxLevel) {
      return description
    }
  }
}
