import { useState, useEffect, useRef, useCallback } from 'react'
import { useSpeechToken } from './use-speech-token'
import {
  SpeechSynthesizer,
  AudioConfig,
  SpeechConfig,
} from 'microsoft-cognitiveservices-speech-sdk'

export const useTTS = () => {
  const token = useSpeechToken()
  const [ready, setReady] = useState(false)
  const synth = useRef<SpeechSynthesizer | null>(null)

  useEffect(() => {
    return () => {
      synth.current?.close()
      synth.current = null
    }
  }, [])

  useEffect(() => {
    if (token.data && !synth.current) {
      const speechConfig = SpeechConfig.fromAuthorizationToken(
        token.data.token,
        token.data.region
      )
      const audioConfig = AudioConfig.fromDefaultSpeakerOutput()

      synth.current = new SpeechSynthesizer(speechConfig, audioConfig)

      setReady(true)
    }
  }, [token.data])

  const speak = useCallback(async (text: string) => {
    if (synth.current) {
      return new Promise((resolve, reject) => {
        synth.current.speakTextAsync(text, resolve, reject)
      })
    }

    return null
  }, [])

  return { ready, speak }
}
