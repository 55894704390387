import { CONFIG } from '../config'
import { getToken } from './firebase'

const getUserAgent = () =>
  'userAgent' in navigator ? navigator.userAgent : 'unknown'

const postLog = async (
  message: string,
  stack?: string,
  metadata: Record<string, any> = {}
): Promise<boolean> => {
  const headers: Record<string, string> = { 'Content-Type': 'application/json' }

  try {
    const token = await getToken()
    headers.Authorization = `Bearer ${token}`
  } catch (err) {
    // Do nothing
  }

  try {
    const result = await fetch(CONFIG.loggingUrl, {
      method: 'POST',
      headers,

      body: JSON.stringify({
        app: 'recorder',
        clientDate: new Date().toISOString(),
        message,
        stack,
        metadata: { ...metadata, userAgent: getUserAgent() },
      }),
    })

    return result.ok
  } catch (err) {
    console.log(err)
    return false
  }
}

export const createLogError =
  (defaultMessage: string, scope: string) =>
  async (error: any, metadata: Record<string, any> = {}) => {
    const message = error?.message ?? defaultMessage
    const stack = error?.stack

    return postLog(message, stack, { scope, ...metadata })
  }
