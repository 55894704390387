import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Recording } from '../types'
import { H2 } from './headings'

interface RecommendationSectionProps {
  recording: Recording
}

const RecommendationSection: React.FC<RecommendationSectionProps> = ({
  recording,
}) => {
  // const arousal = recording.arousal?.summary.arousal

  return (
    <Box>
      <H2>Recommendations</H2>
      {/* {arousal ? ( */}
        {/* {(arousal.neutral_fraction + arousal.high_fraction) * 100 < 50 || recording.textStats.filler_words.length > 3 ? ( */}
        {recording.textStats.filler_words.length > 3 ? (
          <ul>
            {/* {(arousal.neutral_fraction + arousal.high_fraction) * 100 < 50 && (
              <li>
                Speak with variations in pitch to show enthusiasm and keep
                engagement
              </li>
            )} */}
            {recording.textStats.filler_words.length > 3 && (
              <li>
                Work on reduceing filler words, pratice your script more for more confidence!
              </li>
            )}
          </ul>
        ) : (
          <Typography>No Recommendation</Typography>
        )}
      {/* ) : null} */}
    </Box>
  )
}

export default RecommendationSection
