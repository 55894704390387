import { Recording } from '../types'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useRef, useState, useLayoutEffect } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { formatDuration } from '../lib/utils'

interface RecordingPlayerProps {
  recording: Recording
}

const RecordingPlayer: React.FC<RecordingPlayerProps> = ({ recording }) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [paused, setPaused] = useState(true)
  const [sliderValue, setSliderValue] = useState(0)

  useLayoutEffect(() => {
    const audioElement = audioRef.current
    if (!audioElement) return
    const timeUpdateListener = () => {
      setSliderValue(audioElement.currentTime)
    }

    const playPauseListener = () => {
      setPaused(audioElement.paused)
    }

    audioElement.addEventListener('timeupdate', timeUpdateListener)
    audioElement.addEventListener('play', playPauseListener)
    audioElement.addEventListener('pause', playPauseListener)

    return () => {
      audioElement.removeEventListener('timeupdate', timeUpdateListener)
      audioElement.removeEventListener('play', playPauseListener)
      audioElement.removeEventListener('pause', playPauseListener)
    }
  }, [])

  const handlePlayPauseClick = () => {
    if (paused) {
      audioRef.current?.play()
    } else {
      audioRef.current?.pause()
    }

    setPaused(p => !p)
  }

  const PlayPauseIcon = paused ? PlayArrowIcon : PauseIcon

  return (
    <div>
      <Stack spacing={0.5} sx={{ padding: theme => theme.spacing(0, 4) }}>
        <Slider
          value={sliderValue}
          min={0}
          max={recording.duration}
          step={0.01}
          marks={[
            { value: 0, label: formatDuration(sliderValue) },
            {
              value: recording.duration,
              label: formatDuration(recording.duration),
            },
          ].filter(({ value }) => Number.isFinite(value))}
          onChange={(_event, value) => {
            if (audioRef.current && typeof value === 'number') {
              audioRef.current.currentTime = value
              setSliderValue(value)
            }
          }}
        />
        <Box sx={{ textAlign: 'center' }}>
          <IconButton
            onClick={handlePlayPauseClick}
            aria-label={paused ? 'play' : 'pause'}
          >
            <PlayPauseIcon sx={{ fontSize: '3rem' }} />
          </IconButton>
        </Box>
      </Stack>

      <div aria-hidden>
        <audio preload="metadata" ref={audioRef} src={recording.blobUrl} />
      </div>
    </div>
  )
}

export default RecordingPlayer
