import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'

import { PartialRequired, Recording } from '../types'
// import { H2 } from './headings'

interface TextStatsSectionProps {
  recording: PartialRequired<Recording, 'textStats'>
}

const TextStatsSection: React.FC<TextStatsSectionProps> = ({ recording }) => {
  return (
    <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(2) } }}>
      {/* <H2>Transcription Analysis</H2> */}

      {recording.textStats.difficult_words ? (
        <div>
          <Typography component="h3" sx={{ fontWeight: 800, fontSize: '1rem' }}>
            Difficult Words
          </Typography>

          {recording.textStats.difficult_words.length > 0 ? (
            <Box ml={-1}>
              {recording.textStats.difficult_words.map((word, i) => (
                <Box
                  component="span"
                  display="inline-block"
                  mt={1}
                  ml={1}
                  key={i}
                >
                  <Chip label={word} />
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No Difficult Words</Typography>
          )}
        </div>
      ) : null}
      {recording.textStats.filler_words ? (
        <div>
          <Typography component="h3" sx={{ fontWeight: 800, fontSize: '1rem' }}>
            Filler Words
          </Typography>
          {recording.textStats.filler_words.length > 0 ? (
            <Box ml={-1}>
              {recording.textStats.filler_words.map((word, i) => (
                <Box
                  component="span"
                  display="inline-block"
                  mt={1}
                  ml={1}
                  key={i}
                >
                  <Chip label={word.name} />
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No Filler Words</Typography>
          )}
        </div>
      ) : null}
      {recording.textStats.repeated_words ? (
        <div>
          <Typography component="h3" sx={{ fontWeight: 800, fontSize: '1rem' }}>
            Repetitive Words
          </Typography>
          {recording.textStats.repeated_words.length > 0 ? (
            <Box ml={-1}>
              {recording.textStats.repeated_words.map((word, i) => (
                <Box
                  component="span"
                  display="inline-block"
                  mt={1}
                  ml={1}
                  key={i}
                >
                  <Chip label={word.name} />
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No Repetitive Words</Typography>
          )}
        </div>
      ) : null}
    </Box>
  )
}

export default TextStatsSection
