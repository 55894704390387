import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { CONFIG } from '../config'

interface NotEnoughTokensDialogProps {
  open: boolean
  onClose: () => void | Promise<void>
  newTab?: boolean
  tokens?: number
}

const NotEnoughTokensDialog: React.FC<NotEnoughTokensDialogProps> = ({
  open,
  onClose,
  newTab = false,
  tokens,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>You need more tokens!</DialogTitle>
      <DialogContent>
        <p>
          You don&t have enough tokens. You need{' '}
          <b>{CONFIG.tokensPerPitch} per pitch</b> but you only have{' '}
          <b>{tokens}</b>.{' '}
        </p>

        {newTab ? (
          <p>
            Don&apos;t close this page after clicking the button below so you
            can still process your current recording.
          </p>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="error"
          variant="text"
          onClick={onClose}
          sx={{ mr: 1 }}
        >
          Cancel
        </Button>
        <Button
          component={Link}
          to="/settings/tokens"
          color="lightblue"
          variant="contained"
          type="submit"
          target={newTab ? '_blank' : undefined}
        >
          Get more tokens
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotEnoughTokensDialog
