import useSWR, { mutate } from 'swr'
import { authFetch } from '../lib/authFetch'
import { CONFIG } from '../config'
import { useAuth } from './use-auth'
import { TokenPack } from '../types'
import { useCallback } from 'react'

const getTokens = () =>
  authFetch<{ tokens: number }>(`${CONFIG.tokensUrl}/balance`).then(
    res => res.tokens
  )

export const mutateTokens = () => mutate('token-balance')

export const useTokens = () => {
  const auth = useAuth()
  return useSWR(auth.user ? 'token-balance' : null, () => getTokens())
}

const getTokenPacks = () =>
  fetch(`${CONFIG.tokensUrl}/packs`).then(res => res.json())

export const useTokenPacks = () => {
  return useSWR<TokenPack[]>(`${CONFIG.tokensUrl}/packs`, () => getTokenPacks())
}

export const usePurchaseTokenPack = () => {
  return useCallback(async (priceId: string) => {
    const { url: checkoutUrl } = await authFetch<{ url: string }>(
      `${CONFIG.tokensUrl}/checkout`,
      {
        method: 'POST',
        body: JSON.stringify({
          priceId,
        }),
      }
    )

    window.location.href = checkoutUrl
  }, [])
}
