import { RouteProps, Route } from 'react-router-dom'
import { useNotLoggedInRedirect } from '../hooks/use-auth'

const ProtectedRoute: React.FC<RouteProps> = props => {
  useNotLoggedInRedirect()

  return <Route {...props} />
}

export default ProtectedRoute
