import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import MuiLink from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'

import { formatDuration } from '../lib/utils'
import { Recording } from '../types'
import { useNotLoggedInRedirect } from '../hooks/use-auth'
import { useRecordings } from '../hooks/use-recordings'
import { H1 } from './headings'

const formatDateString = (dateString: string): string => {
  return format(new Date(dateString), 'd MMMM, yyyy')
}

const RecordingCard = ({ recording }: { recording: Recording }) => {
  return (
    <Paper
      key={recording._id}
      elevation={3}
      sx={{
        padding: '1rem',
        backgroundColor: recording.pronunciation ? '#B4EEFF' : '#DBE3FF',
        '&>*+*': {
          marginTop: '.5rem',
        },
      }}
    >
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography
          component="p"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          {recording.script?.topic ?? 'Transcription'}
        </Typography>
        <Box flexGrow={1} />
        <Typography variant="body1" mb={2}>
          {formatDuration(recording.duration)}
        </Typography>
      </Box>
      <Box mb={2}>{formatDateString(recording.createdAt)}</Box>
      <Button
        component={Link}
        to={`/recordings/${recording._id}`}
        color="turquoise"
        fullWidth
        variant="contained"
      >
        View Details
      </Button>
    </Paper>
  )
}

const RecordingsPage: React.FC = () => {
  useNotLoggedInRedirect()

  const recordings = useRecordings()

  return (
    <div>
      <H1>Recordings</H1>
      {recordings.data ? (
        recordings.data.length ? (
          <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(6) } }}>
            {recordings.data.map(recording => (
              <RecordingCard key={recording._id} recording={recording} />
            ))}
          </Box>
        ) : (
          <Typography sx={{ textAlign: 'center' }} mt={3}>
            You don&apos;t have any recordings yet. Click{' '}
            <MuiLink component={Link} to="/record">
              here
            </MuiLink>{' '}
            to get started.
          </Typography>
        )
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  )
}

export default RecordingsPage
