import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react'
import {
  AudioConfig,
  SpeechConfig,
  SpeechRecognizer,
  PronunciationAssessmentConfig,
  PronunciationAssessmentGradingSystem,
  PronunciationAssessmentGranularity,
  PronunciationAssessmentResult,
} from 'microsoft-cognitiveservices-speech-sdk'
import { Link, useHistory } from 'react-router-dom'

import { authFetch } from '../lib/authFetch'
import { formatTimeLeft } from '../lib/utils'
import { useRecordAudio } from '../hooks/use-record-audio'
import { useUpdatedRef } from '../hooks/use-updated-ref'
import { formatDuration } from '../lib/utils'
import TokenCount from './TokenCount'
import type { Recording } from '../types'
// import logo from '../assets/pitchlogo.png'
import analysingGif from '../assets/AnalyseAnimation.gif'
import { useAppData } from '../hooks/use-app-data'
import { mutateRecordingById } from '../hooks/use-recordings'
import { useSpeechToken } from '../hooks/use-speech-token'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import StopIcon from '@mui/icons-material/Stop'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Slider from '@mui/material/Slider'
import { useTokens, mutateTokens } from '../hooks/use-tokens'
import NotEnoughTokensDialog from './NotEnoughTokensDialog'
import { CONFIG } from '../config'

type ProcessTranscriptionInput = {
  transcription: string
  blob: Blob | null
  scriptId?: string
  pronunciation?: PronunciationAssessmentResult[]
}

const normalisePronunciation = (results: PronunciationAssessmentResult[]) =>
  results.map(result => result.detailResult)

enum ProcessingError {
  UNKNOWN = -1,

  NOT_ENOUGH_TOKENS,
}

const getProcessedRecording = async ({
  transcription,
  blob,
  scriptId,
  pronunciation,
}: ProcessTranscriptionInput): Promise<
  | { success: true; recording: Recording }
  | { success: false; error: ProcessingError }
> => {
  const data = new FormData()

  data.set('transcription', transcription)
  if (blob) {
    data.set('blob', blob)
  }

  if (scriptId && pronunciation) {
    data.set('scriptId', scriptId)
    data.set(
      'pronunciation',
      JSON.stringify(normalisePronunciation(pronunciation))
    )
  }

  try {
    const result = await authFetch<Recording>(
      process.env.REACT_APP_TRANSCRIPTION_PROCESSING_URL,
      {
        method: 'POST',
        body: data,
      }
    )
    return { success: true, recording: result }
  } catch (err) {
    if (err?._response) {
      if (err._response.status === 403) {
        return { success: false, error: ProcessingError.NOT_ENOUGH_TOKENS }
      }
    }
    return { success: false, error: ProcessingError.UNKNOWN }
  }
}

const Recorder: React.FC = () => {
  const recordAudio = useRecordAudio()
  const [timeLeft, setTimeLeft] = useState(180)
  const [recordingStatus, setRecordingStatus] = useState<
    'idle' | 'error' | 'recording' | 'success'
  >('idle')

  const { selectedScript } = useAppData()
  const history = useHistory()

  const [panelOpen, setPanelOpen] = React.useState(false)

  const [processingStatus, setProcessingStatus] = useState<
    'no text' | 'loading' | 'success'
  >('no text')

  const [showScript, setShowScript] = useState(false)

  //!
  const audioRef = useRef<HTMLAudioElement>(null)
  const [sliderValue, setSliderValue] = useState(0)
  const [duration, setDuration] = useState<number | null>(null)
  const [paused, setPaused] = useState(true)

  const blobUrl = useMemo(() => {
    if (recordAudio.data) {
      return URL.createObjectURL(recordAudio.data)
    }
    return null
  }, [recordAudio.data])

  useLayoutEffect(() => {
    if (!blobUrl) return

    const audio = new Audio()
    audio.src = blobUrl
    audio.currentTime = 1000

    const listener = () => {
      setDuration(audio.duration)
    }

    audio.addEventListener('canplay', listener)
    return () => {
      audio.removeEventListener('canplay', listener)
    }
  }, [blobUrl])

  useLayoutEffect(() => {
    if (!blobUrl) return
    let audioElement = audioRef.current
    const timeUpdateListener = () => {
      setSliderValue(audioElement.currentTime)
    }
    const playPauseListener = () => {
      setPaused(audioElement.paused)
    }

    const addListeners = () => {
      audioElement.addEventListener('timeupdate', timeUpdateListener)
      audioElement.addEventListener('play', playPauseListener)
      audioElement.addEventListener('pause', playPauseListener)
    }

    if (!audioElement) {
      setTimeout(() => {
        audioElement = audioRef.current
        addListeners()
      })
    } else {
      addListeners()
    }
    return () => {
      audioElement?.removeEventListener('timeupdate', timeUpdateListener)
      audioElement?.removeEventListener('play', playPauseListener)
      audioElement?.removeEventListener('pause', playPauseListener)
    }
  }, [blobUrl])

  const handlePlayPauseClick = () => {
    if (paused) {
      audioRef.current?.play()
    } else {
      audioRef.current?.pause()
    }

    setPaused(p => !p)
  }

  const durationDisplay = useMemo(
    () => (duration ? formatDuration(duration) : '--:--.--'),
    [duration]
  )

  const [notEnoughTokens, setNotEnoughTokens] = useState<
    { open: false } | { open: true; newTab?: boolean }
  >({ open: false })
  const tokens = useTokens()
  const speechToken = useSpeechToken()
  const isSpeechLoading = !speechToken.data || !tokens.data
  const reco = useRef<SpeechRecognizer | null>(null)
  const partsRef = useRef<string[]>([])
  const pronunicationParts = useRef<PronunciationAssessmentResult[]>([])
  const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    return () => {
      try {
        reco.current?.close()
      } catch (err) {
        // Do nothing
      }
    }
  }, [])

  const endRecording = useUpdatedRef(async () => {
    if (reco.current) {
      reco.current.stopContinuousRecognitionAsync(() => {
        setRecordingStatus('success')
      })
    }
    await recordAudio.stop()
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    setShowScript(false)
    setPanelOpen(true)
  })

  const proceedToAnalyse = async () => {
    if (tokens.data < CONFIG.tokensPerPitch) {
      setNotEnoughTokens({ open: panelOpen, newTab: true })
      return
    }

    setPanelOpen(false)
    setRecordingStatus('success')
    setProcessingStatus('loading')

    const data: ProcessTranscriptionInput = {
      transcription: partsRef.current.filter(Boolean).join(' '),
      blob: recordAudio.data,
    }

    if (selectedScript) {
      data.scriptId = selectedScript._id
      data.pronunciation = pronunicationParts.current.filter(Boolean)
    }

    const result = await getProcessedRecording(data)

    if (result.success) {
      const { recording } = result

      await mutateRecordingById(recording._id, {
        ...recording,
        blobUrl: URL.createObjectURL(recordAudio.data),
      })
      mutateTokens()

      history.push(`/recordings/${recording._id}`)
    } else if (result.success === false) {
      // ^^ Need this shit bc typescript is stupid
      if (result.error === ProcessingError.NOT_ENOUGH_TOKENS) {
        setNotEnoughTokens({ open: true, newTab: true })
        setPanelOpen(true)
        setProcessingStatus('no text')
      }
    }
  }

  const start = async () => {
    const [tokenData] = await Promise.all([
      speechToken.mutate(),
      recordAudio.setup(),
    ])

    if (tokenData) {
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput()
      const speeckConfig = SpeechConfig.fromAuthorizationToken(
        tokenData.token,
        tokenData.region
      )
      const recognizer: SpeechRecognizer = (reco.current = new SpeechRecognizer(
        speeckConfig,
        audioConfig
      ))

      if (selectedScript) {
        const pronunciationConfig = new PronunciationAssessmentConfig(
          selectedScript.text,
          PronunciationAssessmentGradingSystem.HundredMark,
          PronunciationAssessmentGranularity.Phoneme,
          true
        )

        pronunciationConfig.applyTo(recognizer)
      }

      recognizer.recognized = (_, e) => {
        if (selectedScript) {
          const pronunciationResult = PronunciationAssessmentResult.fromResult(
            e.result
          )
          pronunicationParts.current.push(pronunciationResult)
        }
        partsRef.current.push(e.result.text)
        console.log(partsRef.current)
      }
    }

    await recordAudio.start()

    reco.current.startContinuousRecognitionAsync(() => {
      setRecordingStatus('recording')

      if (intervalRef.current) clearInterval(intervalRef.current)

      intervalRef.current = setInterval(() => {
        setTimeLeft(t => {
          const next = t - 1
          if (next === 0) {
            if (intervalRef.current) clearInterval(intervalRef.current)
            endRecording.current()
            return 0
          }
          return next
        })
      }, 1000)
    })
  }

  const handleClick = async () => {
    switch (recordingStatus) {
      case 'error':
      case 'idle': {
        if (tokens.data < CONFIG.tokensPerPitch) {
          setNotEnoughTokens({ open: true })
        } else {
          start()
        }
        break
      }
      case 'recording': {
        endRecording.current()
        break
      }
    }
  }

  const handleReset = () => {
    if (reco.current) {
      reco.current.close()
    }
    partsRef.current = []
    pronunicationParts.current = []

    setPanelOpen(false)
    setTimeLeft(180)
    setProcessingStatus('no text')
    setRecordingStatus('idle')
    setSliderValue(0)

    recordAudio.reset()
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  return (
    <>
      {processingStatus === 'no text' || showScript ? (
        <Box mt={6} mb={4} pb={4} position="relative">
          <Box mb={4}>
            {recordingStatus === 'idle' ? <TokenCount /> : <Box pt={8} />}
          </Box>
          {/* {processingStatus === 'no text' && (
            <>
              <img
                alt="PitchApp"
                src={logo}
                width="192px"
                height="80px"
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
              />
            </>
          )} */}
          {selectedScript && showScript ? (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              sx={{ overflowY: 'auto' }}
              p={2}
              bgcolor="#E9E9E9"
              whiteSpace="pre-wrap"
              borderRadius={theme => theme.shape.borderRadius}
            >
              {selectedScript.text}
            </Box>
          ) : null}
        </Box>
      ) : null}
      {processingStatus === 'no text' && recordingStatus !== 'success' && (
        <>
          <Box
            sx={{
              fontSize: '3rem',
              textAlign: 'center',
              color: timeLeft < 10 ? 'red' : 'inherit',
              marginBottom: '40px',
            }}
          >
            {formatTimeLeft(timeLeft)}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'relative',
              }}
            >
              {(recordingStatus === 'idle' || recordingStatus === 'error') && (
                <>
                  <Button
                    onClick={handleClick}
                    color="recordingblue"
                    variant="contained"
                    sx={{
                      boxShadow: 'none',
                      width: '100px',
                      height: '100px',
                      borderRadius: '999999px',
                    }}
                    disabled={isSpeechLoading}
                    aria-label="Start Recording"
                  >
                    <span
                      aria-hidden
                      style={{ fontWeight: 'bold', fontSize: '30px' }}
                    >
                      {(recordingStatus === 'idle' ||
                        recordingStatus === 'error') &&
                        'rec'}
                    </span>
                  </Button>
                  <CircularProgress
                    size={115}
                    sx={{
                      color: '#9cd6ff',
                      position: 'absolute',
                      top: -7,
                      left: -7.5,
                      zIndex: -1,
                    }}
                    variant={isSpeechLoading ? 'indeterminate' : 'determinate'}
                    value={100}
                  />
                </>
              )}
              {recordingStatus === 'recording' && (
                <>
                  <Button
                    onClick={handleClick}
                    color="recordingred"
                    variant="contained"
                    sx={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '9999999px',
                    }}
                  >
                    <StopIcon sx={{ color: 'white', fontSize: '50px' }} />
                  </Button>
                  <CircularProgress
                    size={115}
                    sx={{
                      color: '#ffb7b7',
                      position: 'absolute',
                      top: -7,
                      left: -7.5,
                      zIndex: -1,
                    }}
                    variant="determinate"
                    value={Math.floor((180 - timeLeft) / 1.8)}
                  />
                </>
              )}
            </Box>
          </Box>
          {recordingStatus === 'idle' && (
            <>
              <Box mt={6} mb={2} mx="auto" textAlign="center">
                <Button
                  color="green"
                  component={Link}
                  to="/scripts"
                  variant="contained"
                >
                  {selectedScript ? 'Change Script' : 'Select Script'}
                </Button>
              </Box>
              {selectedScript ? (
                <Box textAlign="center">
                  <Typography variant="body2">
                    Currently Selected: <b>{selectedScript.topic}</b>
                  </Typography>
                </Box>
              ) : null}
            </>
          )}
          {recordingStatus === 'recording' && (
            <Box mt={6} mx="auto" justifyContent="center" display="flex">
              <IconButton onClick={() => handleReset()}>
                <RefreshIcon />
              </IconButton>
              {selectedScript ? (
                <>
                  <Box display="inline-block" pl={4} aria-hidden />
                  <IconButton
                    aria-label={showScript ? 'hide script' : 'show script'}
                    onClick={() => setShowScript(s => !s)}
                  >
                    {showScript ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </>
              ) : null}
            </Box>
          )}
        </>
      )}
      {panelOpen && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: '999',
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: '#577ECA',
              maxWidth: '32rem',
              width: '100%',
              padding: '2rem',
              borderTopLeftRadius: '50px',
              borderTopRightRadius: '50px',
              textAlign: 'center',
              '& > * + *': {
                marginTop: '6rem !important',
              },
              margin: '0 auto',
            }}
          >
            <Box mx="auto" maxWidth="24rem">
              <Slider
                color="recordingwhite"
                value={sliderValue}
                min={0}
                max={duration ?? 180}
                step={0.01}
                marks={[
                  { value: 0, label: formatDuration(sliderValue) },
                  { value: duration ?? 180, label: durationDisplay },
                ]}
                onChange={(_event, value) => {
                  if (audioRef.current && typeof value === 'number') {
                    audioRef.current.currentTime = value
                    setSliderValue(value)
                  }
                }}
                componentsProps={{
                  markLabel: {
                    style: {
                      color: 'white',
                    },
                  },
                }}
              />
              <div aria-hidden>
                <audio
                  onLoadStart={() => console.log(1)}
                  preload="metadata"
                  ref={audioRef}
                  src={blobUrl}
                />
              </div>
            </Box>
            <IconButton
              onClick={handlePlayPauseClick}
              sx={{
                backgroundColor: 'white',
                width: '100px',
                height: '100px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
            >
              {paused ? (
                <PlayArrowIcon sx={{ color: '#577ECA', fontSize: '60px' }} />
              ) : (
                <PauseIcon sx={{ color: '#577ECA', fontSize: '50px' }} />
              )}
            </IconButton>
            <Box sx={{ '& > * + *': { marginTop: '1.5rem !important' } }}>
              {duration < 5 ? (
                <Box>
                  <Typography
                    sx={{
                      fontSize: '1.5rem',
                      color: 'white',
                      marginBottom: '20px'
                    }}
                  >
                    Your recording needs to be at least 5 seconds.
                  </Typography>
                </Box>
              ) : (
                <Button
                  size="large"
                  fullWidth
                  color="lightblue"
                  variant="contained"
                  onClick={proceedToAnalyse}
                >
                  Analyse
                </Button>
              )}
              <Button
                size="large"
                fullWidth
                color="recordinggrey"
                variant="contained"
                onClick={handleReset}
              >
                Record Again
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {processingStatus === 'loading' && (
        <>
          <Typography
            sx={{
              marginTop: '35px',
              fontSize: '50px',
              fontWeight: 'bold',
              color: '#6f96c3',
              textAlign: 'center',
            }}
          >
            Analysing...
          </Typography>
          <Box
            component="img"
            display="block"
            alt="loading"
            width="100%"
            height="auto"
            src={analysingGif}
          />
        </>
      )}
      <NotEnoughTokensDialog
        {...notEnoughTokens}
        tokens={tokens.data}
        onClose={() => setNotEnoughTokens({ open: false })}
      />
    </>
  )
}

export default Recorder
