import type { User } from 'firebase/auth'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCurrentUser, onAuthChange, signInWithToken } from '../lib/firebase'

const AuthContext = createContext<{ user: User | null; ready: boolean }>({
  user: null,
  ready: false,
})

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [ready, setReady] = useState(false)

  console.log(user)

  useEffect(() => {
    let unsubscribe: ReturnType<typeof onAuthChange> | undefined

    getCurrentUser().then(async u => {
      setUser(u)

      const token = new URLSearchParams(window.location.search).get('token')
      console.log({ token })
      if (!u && token) {
        setUser(await signInWithToken(token))
      }

      unsubscribe = onAuthChange(user => {
        setUser(user)
      })

      setReady(true)
    })

    return () => {
      unsubscribe?.()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user, ready }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const useLoggedInRedirect = () => {
  const history = useHistory()
  const auth = useAuth()
  useEffect(() => {
    if (auth.user) {
      history.push('/record')
    }
    // eslint-disable-next-line
  }, [auth.user, history.push])
}

export const useNotLoggedInRedirect = () => {
  const history = useHistory()
  const auth = useAuth()
  useEffect(() => {
    if (auth.ready && !auth.user) {
      history.push('/')
    }
    // eslint-disable-next-line
  }, [auth, history.push])
}
