import { useCallback } from 'react'
import useSWR, { mutate } from 'swr'
import { CONFIG } from '../config'

import { authFetch } from '../lib/authFetch'
import { Recording, ApiError } from '../types'
import { useAuth } from './use-auth'

export const getRecordings = async (): Promise<Recording[]> => {
  return authFetch(CONFIG.recordingsUrl)
}

export const useRecordings = () => {
  const auth = useAuth()

  return useSWR<Recording[]>(
    auth.user ? 'recordings' : null,
    () => getRecordings(),
    { focusThrottleInterval: 60 * 60 * 1000 }
  )
}

export const getRecordingById = async (
  recordingId: string
): Promise<Recording> => {
  return authFetch(`${CONFIG.recordingsUrl}/${recordingId}`)
}

export const createRecordingByIdKey = (id: string) => ['recordingById', id]

export const mutateRecordingById = (id: string, value: Recording) =>
  mutate(createRecordingByIdKey(id), value)

export const useRecordingById = (id: string) => {
  const auth = useAuth()
  return useSWR<Recording, ApiError>(
    auth.user ? createRecordingByIdKey(id) : null,
    (_, recordingId) => getRecordingById(recordingId),
    {
      focusThrottleInterval: 1000 * 60 * 10,
    }
  )
}

const deleteRecording = async (id: string): Promise<void> => {
  await authFetch(`${CONFIG.recordingsUrl}/${id}`, { method: 'DELETE' })
}

export const useDeleteRecording = () => {
  const recordings = useRecordings()

  return useCallback(
    async (id: string) => {
      recordings.mutate(
        data => (data ? data.filter(recording => recording._id !== id) : data),
        false
      )

      try {
        await deleteRecording(id)
      } finally {
        recordings.mutate()
        mutate(createRecordingByIdKey(id), null)
      }
    },
    [recordings]
  )
}
