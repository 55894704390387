import { useCallback } from 'react'
import useSWR from 'swr'
import { useAuth } from './use-auth'

import { CONFIG } from '../config'
import { Script } from '../types'
import { authFetch } from '../lib/authFetch'

const getScripts = async (): Promise<Script[]> => {
  return authFetch(CONFIG.scriptsUrl)
}

export const useScripts = () => {
  const auth = useAuth()

  return useSWR(auth.user ? 'scripts' : null, () => getScripts(), {
    focusThrottleInterval: 1000 * 60 * 10,
  })
}

export type CreateScriptInput = Pick<Script, 'text' | 'topic'>

const createScript = async (data: CreateScriptInput): Promise<string> => {
  const { scriptId } = await authFetch(CONFIG.scriptsUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return scriptId
}

export const useCreateScript = () => {
  const { mutate } = useScripts()
  return useCallback(
    async (data: CreateScriptInput) => {
      const scriptId = await createScript(data)

      const optimisticScript = {
        ...data,
        _id: scriptId,
        createdAt: new Date().toISOString(),
      }

      mutate(scripts =>
        scripts ? [optimisticScript, ...scripts] : [optimisticScript]
      )
    },
    [mutate]
  )
}

export type UpdateScriptInput = Partial<Pick<Script, 'text' | 'topic'>>

const updateScript = async (
  scriptId: string,
  data: UpdateScriptInput
): Promise<void> => {
  await authFetch(`${CONFIG.scriptsUrl}/${scriptId}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export const useUpdateScript = () => {
  const { mutate } = useScripts()

  return useCallback(
    async (scriptId, data: UpdateScriptInput) => {
      await updateScript(scriptId, data)

      mutate(scripts => {
        if (scripts) {
          scripts.map(script => {
            if (script._id === scriptId) {
              return { ...scripts, ...data }
            }
            return script
          })
        }
        return scripts
      })
    },
    [mutate]
  )
}

const deleteScript = async (scriptId: string): Promise<void> => {
  await authFetch(`${CONFIG.scriptsUrl}/${scriptId}`, {
    method: 'DELETE',
  })
}

export const useDeleteScript = () => {
  const { mutate } = useScripts()
  return useCallback(
    async (scriptId: string) => {
      const updater = scripts =>
        scripts?.filter(script => script._id !== scriptId)
      mutate(updater, false)

      await deleteScript(scriptId)

      mutate()
    },
    [mutate]
  )
}
