import { createContext, useContext, useMemo, useState } from 'react'
import { Script } from '../types'
import { useRecordings } from './use-recordings'
import { useScripts } from './use-scripts'
import { useTokens } from './use-tokens'

export type AppDataContextType = {
  scripts: Script[] | null
  scriptsById: Record<string, Script> | null
  selectedScript: Script | null
  selectScript: (script: Script | null) => void
}

const AppDataContext = createContext<AppDataContextType | null>(null)

export const AppDataProvider: React.FC = ({ children }) => {
  useRecordings()
  useTokens()
  const scripts = useScripts()
  const [selectedScript, setSelectedScript] = useState<Script | null>(null)

  const scriptsById = useMemo(() => {
    if (!scripts.data) return null

    const result = {}

    for (const script of scripts.data) {
      result[script._id] = script
    }

    return result
  }, [scripts.data])

  return (
    <AppDataContext.Provider
      value={{
        scripts: scripts.data,
        scriptsById,
        selectedScript,
        selectScript: setSelectedScript,
      }}
    >
      {children}
    </AppDataContext.Provider>
  )
}

export const useAppData = () => {
  const context = useContext(AppDataContext)

  if (!context) {
    throw new Error(
      'useAppData must be used in a descendant of AppDataProvider'
    )
  }

  return context
}
