import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'

import { useDeleteMe } from '../hooks/use-delete-me'
import { useState } from 'react'
import { useError } from '../hooks/use-error'

const DeleteMeButton: React.FC = () => {
  const deleteMe = useDeleteMe()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { error, setError, resetError } = useError()

  const handleClose = () => {
    if (deleting) return
    setDialogOpen(false)
  }

  const handleOpen = () => {
    setDialogOpen(true)
  }

  const handleDelete = async () => {
    setDeleting(true)
    resetError()

    const { success } = await deleteMe()

    if (success) {
      handleClose()
    } else {
      setError('Something went wrong deleting your account.')
    }

    setDeleting(false)
  }

  return (
    <>
      <Button
        fullWidth
        size="small"
        variant="outlined"
        color="error"
        onClick={handleOpen}
      >
        Permanently Delete Account
      </Button>

      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          {error && (
            <Alert sx={{ mb: 3 }} severity="error">
              {error}
            </Alert>
          )}
          <Typography>
            Are you sure you wnat to delete your account? This is{' '}
            <b>PERMANENT</b> and cannot be undone. You will:
          </Typography>
          <ul>
            <Typography component="li">Lose all your recordings</Typography>
            <Typography component="li">Lose all your scripts</Typography>
            <Typography component="li">
              Lose all tokens you have purchased. You will not be refunded and
              will not be able to access them again.
            </Typography>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose} disabled={deleting}>
            Cancel
          </Button>
          <Button color="error" disabled={deleting} onClick={handleDelete}>
            Delete Permanently
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteMeButton
