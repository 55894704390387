import { stripTrailingSlash } from './lib/utils'

export const CONFIG = {
  scriptsUrl: stripTrailingSlash(process.env.REACT_APP_SCRIPTS_URL),
  recordingsUrl: stripTrailingSlash(process.env.REACT_APP_RECORDINGS_URL),
  speechTokenUrl: stripTrailingSlash(process.env.REACT_APP_AUTH_TOKEN_URL),
  tokensUrl: stripTrailingSlash(process.env.REACT_APP_TOKENS_URL),
  loggingUrl: stripTrailingSlash(process.env.REACT_APP_LOGGING_URL),

  tokensPerPitch: 15,
}
