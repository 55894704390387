import { PartialRequired, Recording } from '../types'

export const formatDuration = (duration: number): string => {
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60

  if (!Number.isFinite(minutes) || !Number.isFinite(seconds)) {
    return '--:--.--'
  }

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds.toFixed(2)}`
}

export const formatTimeLeft = (duration: number): string => {
  const minutes = Math.floor(duration / 60)
  const seconds = duration % 60
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export const stripTrailingSlash = (url: string): string =>
  url.replace(/\/$/, '')

export const getFormFields = <
  T extends Record<string, any> = Record<string, any>
>(
  form: HTMLFormElement
) => Object.fromEntries(new FormData(form)) as T

export const getTextPreview = (
  text: string,
  options = { maxLength: 110 }
): string => {
  return text.length <= options.maxLength
    ? text
    : text
        .slice(0, options.maxLength - 4)
        .split(' ')
        .slice(0, -1)
        .join(' ') + ' ...'
}

export const convertAzureTimeToMs = (n: number): number => n / 1e4

export const recordingHasTextStats = (
  recording: Recording
): recording is PartialRequired<Recording, 'textStats'> =>
  Boolean(recording.textStats) && Object.keys(recording.textStats).length > 0

export const getNumberPosition = (n: number) => {
  const str = n.toString()

  switch (str[str.length - 1]) {
    case '1':
      return `${str}st`
    case '2':
      return `${str}nd`
    default:
      return `${str}th`
  }
}

export const formatPrice = (cents: number): string => {
  const dollars = Math.floor(cents / 100)
  const remainder = cents % 100
  return `$${dollars}.${remainder < 10 ? '0' : ''}${remainder}`
}

export const clamp = (num: number, min: number, max: number) => {
  if (min > max) {
    throw new Error("pls don't be dumb")
  }
  return num < min ? min : num > max ? max : num
}
