import Button from '@mui/material/Button'
import { useMemo, useState } from 'react'

import { Recording } from '../types'
import ScriptDialog from '../components/ScriptDialog'

interface CreateScriptFromRecordingButtonProps {
  recording: Recording
}

const CreateScriptFromRecordingButton: React.FC<CreateScriptFromRecordingButtonProps> =
  ({ recording }) => {
    const [showDialog, setShowDialog] = useState(false)

    const initialData = useMemo(() => {
      return {
        _id: '',
        createdAt: '',
        topic: '',
        text: recording.transcription,
      }
    }, [recording.transcription])

    return (
      <div>
        <Button color="green" fullWidth onClick={() => setShowDialog(true)}>
          Create Script from Transcription
        </Button>

        <ScriptDialog
          onClose={() => setShowDialog(false)}
          open={showDialog}
          initialData={initialData}
        />
      </div>
    )
  }

export default CreateScriptFromRecordingButton
