import Typography, { TypographyProps } from '@mui/material/Typography'
import { forwardRef } from 'react'

export const H1 = forwardRef<
  HTMLHeadingElement,
  Omit<TypographyProps, 'component'>
>(({ children, sx, ...props }, ref) => (
  <Typography
    sx={{ fontWeight: 800, color: '#6F96C3', fontSize: '2rem' }}
    mb={4}
    {...props}
    ref={ref}
    component="h1"
  >
    {children}
  </Typography>
))

export const H2 = forwardRef<
  HTMLHeadingElement,
  Omit<TypographyProps, 'component'>
>(({ children, sx, ...props }, ref) => (
  <Typography
    sx={{ fontWeight: 800, fontSize: '1.5rem', ...sx }}
    mb={3}
    {...props}
    ref={ref}
    component="h2"
  >
    {children}
  </Typography>
))
