import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import { Script } from '../types'
import { FormEventHandler } from 'react'
import { getFormFields } from '../lib/utils'

import { useCreateScript, useUpdateScript } from '../hooks/use-scripts'
import { useError } from '../hooks/use-error'

interface ScriptDialogProps {
  open: boolean
  onClose: () => void
  update?: boolean
  initialData?: Script
}

const DUMMY_SCRIPT: Script = {
  text: '',
  topic: '',
  _id: '',
  createdAt: '',
}

const ScriptDialog: React.FC<ScriptDialogProps> = ({
  open,
  onClose,
  update = false,
  initialData = DUMMY_SCRIPT,
}) => {
  const { error, setError, resetError } = useError()
  const createScript = useCreateScript()
  const updateScript = useUpdateScript()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    resetError()
    event.preventDefault()

    const data = getFormFields<{ text: string; topic: string }>(
      event.currentTarget
    )

    try {
      if (update) {
        for (const field in initialData) {
          if (initialData[field] === data[field]) {
            delete data[field]
          }
        }

        if (Object.keys(data).length !== 0) {
          await updateScript(initialData._id, data)
        }
      } else {
        await createScript(data)
      }
      onClose()
    } catch (err) {
      setError(err.message ?? 'Unknown Error')
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <DialogTitle>{update ? 'Update' : 'Create New'} Script</DialogTitle>
        <DialogContent>
          {error && (
            <Alert sx={{ mb: 4 }} severity="error">
              {error}
            </Alert>
          )}
          <TextField
            name="topic"
            label="Topic"
            type="text"
            autoFocus
            fullWidth
            required
            variant="filled"
            autoComplete="off"
            defaultValue={initialData.topic}
            sx={{ mb: 4 }}
          />
          <Box position="relative">
            <TextField
              label="Script"
              name="text"
              placeholder="Type your script here..."
              multiline
              required
              type="text"
              fullWidth
              variant="filled"
              rows={10}
              defaultValue={initialData.text}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="button" color="error" variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button color="lightblue" variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ScriptDialog
