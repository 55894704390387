import { useCallback, useState } from 'react'

export const useError = <T = string>() => {
  const [error, setError] = useState<T | null>()

  const resetError = useCallback(() => {
    setError(null)
  }, [])

  return { error, setError, resetError }
}
