import useSWR from 'swr'
import { CONFIG } from '../config'
import { authFetch } from '../lib/authFetch'
import { SpeechTokenResult } from '../types'
import { useAuth } from './use-auth'

const getAzureSpeechToken = () => {
  return authFetch<SpeechTokenResult>(CONFIG.speechTokenUrl)
}

export const useSpeechToken = () => {
  const auth = useAuth()

  return useSWR(
    auth.user ? 'azure-speech-token' : null,
    () => getAzureSpeechToken(),
    {
      focusThrottleInterval: 1000 * 60 * 5,
    }
  )
}
