import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import { useLocation, Link } from 'react-router-dom'

const hideNavRoutes = ['/', '/signup', '/login', '/password-reset']

const BottomNav: React.FC = () => {
  const location = useLocation()
  const showRecordButton = location.pathname !== '/record'

  return hideNavRoutes.includes(location.pathname) ? null : (
    <>
      <Box pt="10rem" />
      <Box
        sx={{ position: 'fixed', bottom: 0, width: '100%', maxWidth: '32rem' }}
      >
        <Stack
          component="footer"
          direction="row"
          px={3}
          py={2}
          sx={{
            position: 'relative',
            backgroundColor: '#A5C9FF',
            '& svg': {
              fill: 'white',
              fontSize: '2rem',
            },
          }}
          justifyContent="space-between"
        >
          <IconButton component={Link} to="/settings">
            <SettingsIcon />
          </IconButton>

          {showRecordButton && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Box
                component={Link}
                to="/record"
                sx={{
                  width: '84px',
                  height: '84px',
                  borderRadius: '9999px',
                  boxShadow: '0 0 0 0.5rem #FFD7D791',
                  backgroundColor: '#FC7D7D',
                  transform: 'translateY(-2rem)',
                  display: 'block',
                }}
              />
            </Box>
          )}

          <IconButton component={Link} to="/recordings">
            <BookmarkIcon />
          </IconButton>
        </Stack>
      </Box>
    </>
  )
}

export default BottomNav
