import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { signOut } from '../lib/firebase'
import { H1, H2 } from './headings'
import { useTokens } from '../hooks/use-tokens'
import { Link } from 'react-router-dom'
import DeleteMeButton from './DeleteMeButton'
import logo from '../assets/pitchlogo.png'

const SettingsPage: React.FC = () => {
  const tokens = useTokens()

  return (
    <Stack spacing={2}>
      <H1 sx={{ fontWeight: 800, color: '#6F96C3', fontSize: '2rem' }}>
        Settings
      </H1>

      <img
        alt="PitchApp"
        src={logo}
        width="192px"
        height="80px"
        style={{
          margin: '0 auto',
          display: 'block',
        }}
      />

      <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(3) } }}>
        <H2>Pitch Tokens</H2>
        <div>
          <Box>
            Tokens: {typeof tokens.data === 'number' ? tokens.data : 'loading'}
          </Box>
        </div>
        <div>
          <Button component={Link} to="/settings/tokens" fullWidth>
            Buy more tokens
          </Button>
        </div>
      </Box>

      <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(3) } }}>
        <H2>Account</H2>
        <div>
          <Button
            onClick={() => signOut()}
            variant="contained"
            color="secondary"
            fullWidth
          >
            Logout
          </Button>
        </div>
        <div>
          <DeleteMeButton />
        </div>
      </Box>
    </Stack>
  )
}

export default SettingsPage
