import { useCallback, useEffect, useRef, useState } from 'react'
import AudioRecorder from 'audio-recorder-polyfill'

// @ts-ignore
if (!window.MediaRecorder) {
  window.MediaRecorder = AudioRecorder
}

const getUserMedia =
  typeof window !== 'undefined'
    ? navigator.mediaDevices?.getUserMedia?.bind(navigator.mediaDevices) ||
      (navigator as any).getUserMedia ||
      (navigator as any).webkitGetUserMedia ||
      (navigator as any).mozGetUserMedia
    : null

enum UseRecordAudioStatus {
  IDLE = 'idle',
  RECORDING = 'recording',
  SUCCESS = 'success',
  ERROR = 'error',
}

enum UseRecordAudioErrorType {
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  PERMISSION_DISMISSED = 'PERMISSION_DISMISSED',
  NO_AUDIO_INPUTS = 'NO_AUDIO_INPUTS',
  CANNOT_ACCESS_INPUT = 'CANNOT_ACCESS_INPUT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

type UseRecordAudioError = {
  type: UseRecordAudioErrorType
}

const getErrorType = (err: any): UseRecordAudioErrorType => {
  if (!('name' in err && 'message' in err)) {
    return UseRecordAudioErrorType.UNKNOWN_ERROR
  }

  const { name, message } = err

  switch (name) {
    case 'NotAllowedError': {
      if (/denied/.test(message)) {
        return UseRecordAudioErrorType.PERMISSION_DENIED
      } else if (/dismissed/.test(message)) {
        return UseRecordAudioErrorType.PERMISSION_DISMISSED
      }
      return UseRecordAudioErrorType.UNKNOWN_ERROR
    }

    case 'NotFoundError':
      return UseRecordAudioErrorType.NO_AUDIO_INPUTS

    case 'AbortError':
    case 'NotReadableError':
      return UseRecordAudioErrorType.CANNOT_ACCESS_INPUT

    default:
      return UseRecordAudioErrorType.UNKNOWN_ERROR
  }
}

type UseRecordAudioState =
  | { data: null; error: null; status: UseRecordAudioStatus.IDLE }
  | { data: null; error: null; status: UseRecordAudioStatus.RECORDING }
  | { data: Blob; error: null; status: UseRecordAudioStatus.SUCCESS }
  | {
      data: null
      error: UseRecordAudioError
      status: UseRecordAudioStatus.ERROR
    }

type UseRecordAudioResult = UseRecordAudioState & {
  start: () => Promise<void>
  setup: () => Promise<void>
  stop: () => Promise<void>
  reset: () => void
  isSupported: boolean
}

export const useRecordAudio = (): UseRecordAudioResult => {
  const [state, setState] = useState<UseRecordAudioState>({
    status: UseRecordAudioStatus.IDLE,
    data: null,
    error: null,
  })
  const [isSupported, setIsSupported] = useState(false)
  const data = useRef<{
    stream?: MediaStream | null
    recorder?: any
    chunks?: Blob[]
  }>({})

  const setError = useCallback(async (type: UseRecordAudioErrorType) => {
    setState({
      error: { type },
      data: null,
      status: UseRecordAudioStatus.ERROR,
    })
  }, [])

  useEffect(() => {
    if (!getUserMedia) {
      setIsSupported(false)
      setError(UseRecordAudioErrorType.NOT_SUPPORTED)
    }
  }, [setError])

  const cleanup = useCallback(() => {
    if (data.current.stream) {
      data.current.stream.getTracks().forEach(track => track.stop())
      delete data.current.stream
    }
  }, [])

  useEffect(() => {
    return () => {
      cleanup()
    }
  }, [cleanup])

  const setup = useCallback(async () => {
    if (!getUserMedia || state.status === UseRecordAudioStatus.RECORDING) return
    try {
      await getUserMedia({ audio: true, video: false })
    } catch (err) {
      setError(getErrorType(err))
    }
  }, [setError, state.status])

  const start = useCallback(async () => {
    if (!getUserMedia || state.status === UseRecordAudioStatus.RECORDING) return

    try {
      const stream = await getUserMedia({ audio: true, video: false })

      const MediaRecorder = (window as any).MediaRecorder

      if (MediaRecorder) {
        const recorder = new MediaRecorder(stream)
        recorder.addEventListener('dataavailable', (event: { data: Blob }) => {
          if (data.current.chunks) data.current.chunks.push(event.data)
        })

        recorder.start()

        setState({
          data: null,
          error: null,
          status: UseRecordAudioStatus.RECORDING,
        })

        data.current = { ...data.current, recorder, stream, chunks: [] }
      }
    } catch (err: any) {
      setError(getErrorType(err))
    }
  }, [state.status, setError])

  const stop = useCallback(async () => {
    cleanup()

    let blob: Blob | undefined = undefined
    const { recorder, chunks } = data.current

    if (recorder) {
      if (recorder.state !== 'inactive') recorder.stop()
      await new Promise<void>(resolve => {
        setTimeout(() => {
          const length = chunks?.length
          if (!chunks || !length) {
            return resolve()
          } else if (length > 1) {
            blob = new Blob(chunks, {
              type: chunks[0].type,
            })
          } else if (length === 1) {
            blob = chunks[0]
          }
          resolve()
        }, 100)
      })
    }
    if (blob) {
      setState({
        status: UseRecordAudioStatus.SUCCESS,
        data: blob as Blob,
        error: null,
      })
    } else {
      setError(UseRecordAudioErrorType.UNKNOWN_ERROR)
    }
  }, [cleanup, setError])

  const reset = useCallback(() => {
    setState({ status: UseRecordAudioStatus.IDLE, data: null, error: null })
  }, [])

  return { ...state, setup, start, stop, reset, isSupported }
}
