import { createLogError } from './errors'
import { getToken } from './firebase'

const logError = createLogError('Unexpected API Error', 'API Server Error')

export const authFetch = async <T>(
  url: string,
  init?: RequestInit
): Promise<T> => {
  const response = await fetch(url, {
    ...init,
    headers: { ...init?.headers, Authorization: `Bearer ${await getToken()}` },
  })

  const json = await response.json()

  if (!response.ok) {
    json._response = response
    if (response.status >= 500) {
      logError(json, {
        url,
        status: response.status,
        method: init?.method ?? 'GET',
      })
    }
    throw json
  }

  return json
}
