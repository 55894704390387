import type { PaletteColor, PaletteColorOptions } from '@mui/material'
import { createTheme } from '@mui/material/styles'

type CustomColors = {
  turquoise: true
  green: true
  lightblue: true
  recordingblue: true
  recordingred: true
  recordinggrey: true
  recordingwhite: true
}

type ColorExtension<T> = {
  [K in keyof CustomColors]: CustomColors[K] extends true ? T : never
}

declare module '@mui/material/styles' {
  interface Palette extends ColorExtension<PaletteColor> {}
  interface PaletteOptions extends ColorExtension<PaletteColorOptions> {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides extends ColorExtension<true> {}
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides extends ColorExtension<true> {}
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides extends ColorExtension<true> {}
}

export default createTheme({
  palette: {
    turquoise: {
      main: '#42bae0',
      contrastText: '#ffffff',
    },
    green: {
      main: '#62CA7F',
      contrastText: '#ffffff',
    },
    lightblue: {
      main: '#6EA9EE',
      contrastText: '#ffffff',
    },
    recordingblue: {
      main: '#85CCFF',
      contrastText: '#2F2D2D',
    },
    recordingred: {
      main: '#FF4E4E',
      contrastText: '#2F2D2D',
    },
    recordinggrey: {
      main: '#979595',
      contrastText: '#ffffff',
    },
    recordingwhite: {
      main: '#ffffff',
      contrastText: '#000000',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: { padding: '1rem 1.5rem' },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
  },
})
