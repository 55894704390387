import { useCallback, useEffect } from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { createLogError } from '../lib/errors'
import { PropsOf } from '../types'

const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        maxWidth="24rem"
        width="100%"
        sx={{ '&>*+*': { marginTop: theme => theme.spacing(3) } }}
        textAlign="center"
      >
        <div>
          <Typography variant="h4" component="h1">
            Some went wrong
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            We&apos;ve logged this issues and we're working on fixing it.
          </Typography>
        </div>
        <div>
          <Button onClick={() => resetErrorBoundary()} variant="contained">
            Try Again
          </Button>
        </div>
      </Box>
    </Box>
  )
}

const logReactError = createLogError('React Error', 'React')
const logGlobalError = createLogError('React Error', 'Global Error')
const logUnhandledRejection = createLogError(
  'React Error',
  'Unhandled Rejection'
)

const ErrorBoundary: React.FC = ({ children }) => {
  useEffect(() => {
    const globalListener = error => {
      logGlobalError(error)
    }

    const unhandledRejectionListener = error => {
      logUnhandledRejection(error)
    }

    window.addEventListener('error', globalListener)
    window.addEventListener('unhandledrejection', unhandledRejectionListener)

    return () => {
      window.removeEventListener('error', globalListener)
      window.removeEventListener(
        'unhandledrejection',
        unhandledRejectionListener
      )
    }
  }, [])

  const onError = useCallback<PropsOf<ReactErrorBoundary>['onError']>(
    (error, info) => {
      logReactError(error, info)
    },
    []
  )

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
