import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import BottomNav from './components/BottomNav'
import ErrorBoundary from './components/ErrorBoundary'
import LoginSignup from './components/LoginSignup'
import Recorder from './components/Recorder'
import RecordingsPage from './components/RecordingsPage'
import SettingsPage from './components/SettingsPage'
import { AuthProvider } from './hooks/use-auth'
import { AppDataProvider } from './hooks/use-app-data'
import ScriptsPage from './components/ScriptsPage'
import theme from './theme'
import RecordingByIdPage from './components/RecordingByIdPage'
import ProtectedRoute from './components/ProtectedRoute'
import BuyTokensPage from './components/BuyTokensPage'
import PasswordResetPage from './components/PasswordResetPage'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <AuthProvider>
          <AppDataProvider>
            <BrowserRouter>
              <Stack
                sx={{ maxWidth: '32rem', margin: '0 auto', height: '100%' }}
              >
                <Box
                  component="main"
                  px={2}
                  pt={2}
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Switch>
                    <ProtectedRoute path={['/scripts', '/scripts/:id']} exact>
                      <ScriptsPage />
                    </ProtectedRoute>
                    <ProtectedRoute path="/settings/tokens">
                      <BuyTokensPage />
                    </ProtectedRoute>
                    <ProtectedRoute path="/settings" exact>
                      <SettingsPage />
                    </ProtectedRoute>
                    <ProtectedRoute path="/recordings" exact>
                      <RecordingsPage />
                    </ProtectedRoute>
                    <ProtectedRoute path="/recordings/:id" exact>
                      <RecordingByIdPage />
                    </ProtectedRoute>
                    <ProtectedRoute path="/record" exact>
                      <Recorder />
                    </ProtectedRoute>
                    <Route path="/password-reset" exact>
                      <PasswordResetPage />
                    </Route>
                    <Route path={['/', '/login']} exact>
                      <LoginSignup mode="login" />
                    </Route>
                    <Route path="/signup" exact>
                      <LoginSignup mode="signup" />
                    </Route>
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </Box>
                <BottomNav />
              </Stack>
            </BrowserRouter>
          </AppDataProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
