const speechRateScoreAndRecommendation: {
  maxRange: number,
  evaluation: string,
  recommendation: string
}[] = [
  {
    maxRange: 130,
    evaluation: 'too slow',
    recommendation:
      '. We Recommend your speech pace at 140 - 160'
  },
  {
    maxRange: 140,
    evaluation: 'okay',
    recommendation:
      '. You might want to aim for a speech pace between 140 - 160. Speak a little faster.'
  },
  {
    maxRange: 160,
    evaluation: 'ideal',
    recommendation:
      '. Keep it up!'
  },
  {
    maxRange: 170,
    evaluation: 'okay',
    recommendation:
      '. You might want to aim for a speech pace between 140 - 160. Speak a little slower.'
  },
  {
    maxRange: 190,
    evaluation: 'a bit fast',
    recommendation:
      '. You might want to aim for a speech pace of 140 - 160.'
  },
  {
    maxRange: 999,
    evaluation: 'too fast',
    recommendation:
      '. Please calm down and speak slower. You want to aim for a speech pace at 140 - 160.'
  },
]

export const getEvaluationForSpeechRate = (wpm: number): string => {
  for (const { maxRange, evaluation } of speechRateScoreAndRecommendation) {
    if (wpm < maxRange) {
      return evaluation
    }
  }
}

export const getRecommendationForSpeechRate = (wpm: number):string => {
  for (const { maxRange, recommendation } of speechRateScoreAndRecommendation) {
    if (wpm < maxRange) {
      return recommendation
    }
  }
}