import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

interface ResultCircleProps {
  color: string
  title: string
  value: number
  popupContent: React.ReactNode
}

const ResultCircle: React.FC<ResultCircleProps> = ({
  color,
  title,
  value,
  popupContent,
}) => {
  const [v, setV] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  useEffect(() => {
    const frames = []

    frames.push(
      requestAnimationFrame(() => {
        frames.push(
          requestAnimationFrame(() => {
            setV(value)
          })
        )
      })
    )

    return () => {
      frames.forEach(frame => cancelAnimationFrame(frame))
    }
  }, [value])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
          }}
          mb={2}
        >
          <CircularProgress
            variant="determinate"
            value={100}
            thickness={6}
            size={70}
            sx={{ color: '#dfdfdf' }}
          />
          <CircularProgress
            variant="determinate"
            value={v}
            thickness={6}
            size={70}
            sx={{
              color,
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>{Math.round(value)}</Typography>
          </Box>
        </Box>
        <Typography>{title}</Typography>
      </Box>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent>{popupContent}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResultCircle
