import { FormEventHandler, ChangeEventHandler, useEffect } from 'react'
import { useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import { visuallyHidden } from '@mui/utils'

import { usePurchaseTokenPack, useTokenPacks } from '../hooks/use-tokens'
import { ReactComponent as CoinSmall } from '../assets/coin-small.svg'
import { formatPrice } from '../lib/utils'
import { CONFIG } from '../config'

const BuyTokensPage: React.FC = () => {
  const tokenPacks = useTokenPacks()
  const tokenPacksLoading = !tokenPacks.data && !tokenPacks.error

  const [selected, setSelected] = useState<string | null>()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const packs = tokenPacks.data

    if (!packs) return

    setSelected(current => {
      if (!current) return packs[0].price_id

      if (!packs.find(({ price_id }) => price_id === current))
        return packs[0].price_id

      return current
    })
  }, [tokenPacks.data])

  const purchaseTokenPack = usePurchaseTokenPack()

  const handleCheckout: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()
    setSubmitting(true)

    try {
      await purchaseTokenPack(selected)
    } finally {
      setSubmitting(false)
    }
  }

  const handlePackChange: ChangeEventHandler<HTMLInputElement> = event => {
    setSelected(event.target.value)
  }

  return (
    <div>
      <Box
        sx={{
          background: '#6F96C3',
          mt: -2,
          mx: -2,
          pt: 6,
          pb: 4,
          px: 2,
          textAlign: 'center',
          color: 'white',
        }}
      >
        <Box component="h1" mb={3}>
          Pitch Tokens
        </Box>
        <Typography sx={{ fontWeight: 'bold' }}>
          Purchase Pitch Tokens to start practicing your pitch today.
        </Typography>
      </Box>

      <Box pt={6}>
        {tokenPacks.data ? (
          <form onSubmit={handleCheckout}>
            <FormControl component="fieldset" sx={{ display: 'flex' }}>
              <FormLabel sx={visuallyHidden} component="legend">
                Choose a token pack to purchase
              </FormLabel>
              <RadioGroup
                sx={{ '&>*+*': { marginTop: theme => theme.spacing(2) } }}
                onChange={handlePackChange}
              >
                {tokenPacks.data.map(pack => {
                  const checked = pack.price_id === selected

                  return (
                    <Box
                      component="label"
                      key={pack.id}
                      display="flex"
                      alignItems="center"
                      pl={1}
                      pr={2}
                      py={2}
                      sx={{
                        border: checked
                          ? '2px solid #8CBAFF'
                          : '2px solid #c6ddff',
                        boxShadow: checked ? '0 0 0 2px #8CBAFF' : 'none',
                        borderRadius: '9px',
                        backgroundColor: '#EBF4FF',
                        transition: 'all 250ms',
                        cursor: 'pointer',
                        fontSize: '1.25rem',
                      }}
                    >
                      <Radio checked={checked} value={pack.price_id} />
                      <Box component={CoinSmall} mr={2} />

                      <Typography>{pack.name}</Typography>
                      <Box sx={{ flex: '1' }} />
                      <Typography sx={{ fontWeight: 'bold' }}>
                        {formatPrice(pack.price)}
                      </Typography>
                    </Box>
                  )
                })}
              </RadioGroup>
            </FormControl>

            <Box mt={4}>
              <Button type="submit" fullWidth disabled={submitting}>
                Buy Now
              </Button>
            </Box>

            <Box textAlign="center" mt={4}>
              <Typography variant="body2">
                {CONFIG.tokensPerPitch} tokens can used for each 3 minute pitch.
              </Typography>
            </Box>
          </form>
        ) : null}

        {tokenPacksLoading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </div>
  )
}

export default BuyTokensPage
