import type { RawArousal, Recording } from '../types'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import { Fragment, useMemo, useState } from 'react'
import { convertAzureTimeToMs } from '../lib/utils'
import { H2 } from './headings'
import { useTTS } from '../hooks/use-tts'

interface TranscriptionSectionProps {
  recording: Recording
}

const PRONUNCIATION_THRESHOlD = 80

const RedHighlight = (props: BoxProps) => (
  <Box component="span" bgcolor="#FFA7A799" {...props} />
)

const YellowHighlight = (props: BoxProps) => (
  <Box component="span" bgcolor="#FFEC4099" {...props} />
)

// const getHighestEnergyLevel = (data: RawArousal): keyof RawArousal => {
//   let maxLevel = null
//   let maxFraction = 0

//   Object.entries(data).forEach(([level, fraction]) => {
//     if (fraction > maxFraction) {
//       maxLevel = level
//       maxFraction = fraction
//     }
//   })

//   return maxLevel
// }

type Word = {
  offset: number
  duration: number
  text: string
  display: string
  pronunciationScore: number
  pronunciationType: Recording['pronunciation'][0]['Words'][0]['PronunciationAssessment']['ErrorType']
  energy?: keyof RawArousal
  phonemes: Recording['pronunciation'][0]['Words'][0]['Phonemes']
}

const getMessagesForWord = ({
  energy,
  pronunciationScore,
  pronunciationType,
}: Word): string[] => {
  const messages = []

  if (energy === 'low') {
    messages.push('Low energy')
  }

  if (
    pronunciationScore < PRONUNCIATION_THRESHOlD ||
    pronunciationType === 'Mispronunciation'
  ) {
    messages.push('Mispronunciation')
  }

  return messages
}

const returnPhoneme = ({ phonemes }: Word): any => {
  return phonemes
}

const PronunciationArousalTranscription = ({
  recording,
}: TranscriptionSectionProps) => {
  const [showHelp, setShowHelp] = useState(false)
  const tts = useTTS()

  const handleDialogClose = () => {
    setShowHelp(false)
  }

  const words: Word[] = useMemo(() => {
    // const data = recording.arousal?.time_series

    const pronunciation = recording.pronunciation
      .map(chunk => {
        const displayWords = chunk.Display.split(' ').filter(Boolean)
        return chunk.Words.map((word, i) => ({
          offset: convertAzureTimeToMs(word.Offset),
          duration: convertAzureTimeToMs(word.Duration),
          text: word.Word,
          display: displayWords[i],
          pronunciationScore: word.PronunciationAssessment.AccuracyScore,
          pronunciationType: word.PronunciationAssessment.ErrorType,
          phonemes: word.Phonemes,
        }))
      })
      .flat()

    let result = pronunciation

    // if (data) {
    //   const arousalTimeLength = data[1].timestamp
    //   const arousalTimeSeries = data.map(entry =>
    //     getHighestEnergyLevel(entry.raw.arousal)
    //   )

    //   result = pronunciation.map(word => {
    //     const midpoint = word.offset + word.duration / 2
    //     const arousalIndex = Math.floor(midpoint / arousalTimeLength)
    //     const energyLevel = arousalTimeSeries[arousalIndex]

    //     return {
    //       ...word,
    //       energy: energyLevel,
    //     }
    //   })
    // }

    return result
  }, [recording])

  const [errorDetailsWord, setErrorDetailsWord] = useState<Word | null>(null)
  const [showErrorDetails, setShowErrorDetails] = useState(false)

  const renderWord = (word: Word) => {
    let text: React.ReactNode = word.display
    if (
      word.pronunciationScore < PRONUNCIATION_THRESHOlD ||
      word.pronunciationType !== 'None'
      // || (word.energy && word.energy === 'low')
    ) {
      text = (
        <RedHighlight
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setErrorDetailsWord(word)
            setShowErrorDetails(true)
          }}
        >
          {word.display}
        </RedHighlight>
      )
    }

    return <span>{text} </span>
  }

  const handleErrorDetailsClose = () => {
    setShowErrorDetails(false)
  }

  const handleTTS = async () => {
    await tts.speak(errorDetailsWord.text)
  }

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <H2 mb={0}>Transcription</H2>

        <IconButton color="primary" onClick={() => setShowHelp(true)}>
          <InfoIcon />
        </IconButton>
      </Box>

      <Box component="pre" whiteSpace="pre-wrap">
        {words.map(word => (
          <>
            <Fragment key={word.offset}>{renderWord(word)}</Fragment>
          </>
        ))}
      </Box>

      <Dialog
        open={showErrorDetails}
        onClose={handleErrorDetailsClose}
        maxWidth="xs"
      >
        <DialogContent
          sx={{ '&>*+*': { marginTop: theme => theme.spacing(3) } }}
        >
          <Box
            component="ul"
            textAlign="center"
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0,
            }}
          >
            {errorDetailsWord
              ? getMessagesForWord(errorDetailsWord).map((message, i) => (
                <Typography component="li" variant="h6" key={i} mb={2}>
                  {message}
                </Typography>
              ))
              : null}
          </Box>
          <Box textAlign="center">
            {errorDetailsWord?.phonemes
              ? returnPhoneme(errorDetailsWord).map((phoneme, i) => (
                <Fragment>
                  {i !== 0 ? (
                    <span style={{ color: 'black', fontWeight: 600 }}>-</span>
                  ) : null}
                  <Typography
                    sx={{
                      display: 'inline',
                      color:
                        phoneme.PronunciationAssessment.AccuracyScore > 20
                          ? 'blue'
                          : 'red',
                      textDecoration:
                        phoneme.PronunciationAssessment.AccuracyScore > 20
                          ? 'none'
                          : 'underline',
                    }}
                    component="li"
                    variant="h6"
                    key={i}
                    mb={2}
                  >
                    <span>{phoneme.Phoneme}</span>
                  </Typography>
                </Fragment>
              ))
              : null}
          </Box>
          <Box textAlign="center">
            <IconButton
              size="large"
              sx={{ fontSize: '48px' }}
              disabled={!tts.ready}
              onClick={handleTTS}
            >
              <VolumeUpIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <div>
            <Button fullWidth onClick={handleErrorDetailsClose}>
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showHelp}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>How to Use</DialogTitle>
        <DialogContent>
          <Typography mb={3}>
            Tap on <RedHighlight>red highlighted words</RedHighlight> to view
            energy and pronunciation issues.
          </Typography>
          <Typography>
            Tap on <YellowHighlight>yellow highlighted words</YellowHighlight>{' '}
            to see missing words from your script.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const SimpleTranscription = ({ recording }: TranscriptionSectionProps) => {
  const wordsDisplay = recording.transcription.split(' ')
  const [isOpen, setIsOpen] = useState(false)
  const [errorWord, setErrorWord] = useState('')

  const getMessagesForNonScriptWord = (word) => {
    const messages = []

    if (recording.textStats.filler_words) {
      if (recording.textStats.filler_words.length > 0) {
        recording.textStats.filler_words.forEach((fillerword) => {
          if (word.toLowerCase() === fillerword.name.toLowerCase()) {
            messages.push('This is a filler word')
          }
        })
      }
    }
    if (recording.textStats.repeated_words) {
      if (recording.textStats.repeated_words.length > 0) {
        recording.textStats.repeated_words.forEach((repeatedword) => {
          if (word.toLowerCase() === repeatedword.name.toLowerCase()) {
            messages.push('This is a repeated word')
          }
        })
      }
    }
    if (recording.textStats.overused_words) {
      if (recording.textStats.overused_words.length > 0) {
        recording.textStats.overused_words.forEach((overusedword) => {
          if (word.toLowerCase() === overusedword.name.toLowerCase()) {
            messages.push('This is an overused word')
          }
        })
      }
    }
    return messages
  }

  const renderWord = (word) => {
    let text: React.ReactNode = word
    let numOfProb = 0
    if (recording.textStats.filler_words) {
      if (recording.textStats.filler_words.length > 0) {
        recording.textStats.filler_words.forEach((fillerword) => {
          if (word.toLowerCase() === fillerword.name.toLowerCase()) {
            numOfProb += 1
          }
        })
      }
    }
    if (recording.textStats.repeated_words) {
      if (recording.textStats.repeated_words.length > 0) {
        recording.textStats.repeated_words.forEach((repeatedword) => {
          if (word.toLowerCase() === repeatedword.name.toLowerCase()) {
            numOfProb += 1
          }
        })
      }
    }
    if (recording.textStats.overused_words) {
      if (recording.textStats.overused_words.length > 0) {
        recording.textStats.overused_words.forEach((overusedword) => {
          if (word.toLowerCase() === overusedword.name.toLowerCase()) {
            numOfProb += 1
          }
        })
      }
    }
    if (numOfProb === 1) {
      text = (
        <YellowHighlight
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setIsOpen(true)
            setErrorWord(word)
          }}
        >
          {word}
        </YellowHighlight>
      )
    }
    if (numOfProb > 1) {
      text = (
        <RedHighlight
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setIsOpen(true)
            setErrorWord(word)
          }}
        >
          {word}
        </RedHighlight>
      )
    }

    return <span>{text} </span>
  }

  const handleErrorDetailsClose = () => {
    setIsOpen(false)
  }

  return (
    <div>
      <H2>Transcription</H2>
      <Box component="pre" whiteSpace="pre-wrap">
        {wordsDisplay.map((word, i) => (
          <Fragment key={i}>{renderWord(word.replace(/[.,]/g, ''))}</Fragment>
        ))}
      </Box>
      <Dialog
        open={isOpen}
        onClose={handleErrorDetailsClose}
        maxWidth="xs"
      >
        <DialogContent
          sx={{ '&>*+*': { marginTop: theme => theme.spacing(3) } }}
        >
          <Box
            component="ul"
            textAlign="center"
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0,
            }}
          >
            {getMessagesForNonScriptWord(errorWord).map((message, i) => (
              <Typography>{message}</Typography>
            ))}
          </Box>
          <div>
            <Button fullWidth onClick={handleErrorDetailsClose}>
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const TranscriptionSection: React.FC<TranscriptionSectionProps> = props => {
  const hasPronunciation = Boolean(props.recording.pronunciation)

  const Component = hasPronunciation
    ? PronunciationArousalTranscription
    : SimpleTranscription

  return <Component {...props} />
}

export default TranscriptionSection
