import { Link } from 'react-router-dom'
import { FormEventHandler, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import MuiLink from '@mui/material/Link'

import logo from '../assets/pitchlogo.png'
import { useLoggedInRedirect } from '../hooks/use-auth'
import {
  getMessageForError,
  isFirebaseError,
  resetPassword,
} from '../lib/firebase'
import { useError } from '../hooks/use-error'

const PasswordResetPage: React.FC = () => {
  useLoggedInRedirect()

  const [formStatus, setFormStatus] = useState<'idle' | 'loading' | 'success'>(
    'idle'
  )
  const emailError = useError()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()
    emailError.resetError()
    setFormStatus('loading')
    const data = Object.fromEntries(new FormData(event.currentTarget))

    try {
      await resetPassword(data.email as string)
      setFormStatus('success')
    } catch (err) {
      if (isFirebaseError(err)) {
        emailError.setError(getMessageForError(err))
      }
      setFormStatus('idle')
    }
  }

  return (
    <Box
      sx={{
        maxWidth: '24rem',
        '&>*+*': { marginTop: theme => theme.spacing(4) },
      }}
      pt={8}
    >
      <Box
        component="img"
        display="block"
        mx="auto"
        src={logo}
        alt="logo"
        width="192px"
        height="80px"
        mb={8}
      />

      {formStatus === 'success' ? (
        <>
          <Alert severity="success">
            We&apos;ve sent you an email to reset your password.
          </Alert>
          <Box textAlign="center">
            <MuiLink component={Link} to="/login">
              Back to Login
            </MuiLink>
          </Box>
        </>
      ) : null}

      {formStatus !== 'success' ? (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            '&>*+*': { marginTop: theme => theme.spacing(3) },
          }}
        >
          {emailError.error ? (
            <div>
              <Alert severity="error" onClose={() => emailError.resetError()}>
                {emailError.error}
              </Alert>
            </div>
          ) : null}

          <div>
            <TextField
              required
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              disabled={formStatus === 'loading'}
              label="Email"
              fullWidth
            />
          </div>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <MuiLink component={Link} to="/login">
              Back to Login
            </MuiLink>
            <Button type="submit" disabled={formStatus === 'loading'}>
              Reset Password
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default PasswordResetPage
