import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useAppData } from '../hooks/use-app-data'
import ScriptDialog from './ScriptDialog'
import { useDeleteScript } from '../hooks/use-scripts'
import { Script } from '../types'
import { getTextPreview } from '../lib/utils'

const ScriptsPage: React.FC = () => {
  const { scripts, selectedScript, selectScript, scriptsById } = useAppData()
  const deleteScript = useDeleteScript()

  const [formState, setFormState] = useState<{
    show: boolean
    updateData?: Script
  }>({ show: false })

  const history = useHistory()
  const { id: scriptId } = useParams<{ id?: string }>()

  useEffect(() => {
    if (scriptId) {
      if (scriptId === 'new') {
        return setFormState({ show: true })
      }
      if (scriptsById[scriptId]) {
        setFormState({ show: true, updateData: scriptsById[scriptId] })
      } else {
        history.push('/scripts')
      }
    } else {
      setFormState({ show: false })
    }
  }, [scriptId, history, scriptsById])

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <IconButton size="large" component={Link} to="/record">
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          onClick={() => history.push('/scripts/new')}
          disabled={!scripts}
          size="large"
          color="turquoise"
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {scripts ? (
        <Box
          sx={{
            '&>*+*': { marginTop: theme => theme.spacing(6) },
          }}
        >
          {scripts.map(script => {
            const isSelected = selectedScript?._id === script._id

            return (
              <Paper
                key={script._id}
                elevation={3}
                sx={{
                  padding: '1rem',
                  backgroundColor: '#DBE3FF',
                  '&>*+*': {
                    marginTop: '.5rem',
                  },
                }}
              >
                <Box display="flex" alignItems="center" marginBottom={2}>
                  <Typography
                    component="p"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                    }}
                  >
                    {script.topic}
                  </Typography>
                  <Box flexGrow={1} />
                  <IconButton
                    onClick={() => {
                      history.push(`/scripts/${script._id}`)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      deleteScript(script._id)
                      if (isSelected) {
                        selectScript(null)
                      }
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
                <Typography component="p" mb={1}>
                  {getTextPreview(script.text)}
                </Typography>
                <Button
                  variant={isSelected ? 'outlined' : 'contained'}
                  color="turquoise"
                  fullWidth
                  onClick={() => selectScript(isSelected ? null : script)}
                >
                  {isSelected ? 'Selected' : 'Practice'}
                </Button>
              </Paper>
            )
          })}
        </Box>
      ) : null}

      {!scripts ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}

      <ScriptDialog
        open={formState.show}
        onClose={() => {
          history.push('/scripts')
        }}
        update={Boolean(formState.updateData)}
        initialData={formState.updateData}
      />
    </div>
  )
}

export default ScriptsPage
