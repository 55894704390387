import { useParams, useHistory, Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useMemo, useState } from 'react'
// import { Line } from 'react-chartjs-2'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

// import DoughnutChart from './DoughnutChart'
import HorizontalBarChart from './HorizontalBarChart'
import HorizontalBarChartNonScriptMode from './HorizontalBarChartNonScriptMode'
import { useDeleteRecording, useRecordingById } from '../hooks/use-recordings'
import {
  PronunciationScores,
  Recording,
  // PropsOf
} from '../types'
import ResultCircle from './ResultCircle'
import RecordingPlayer from './RecordingPlayer'
import TranscriptionSection from './TranscriptionSection'
import {
  clamp,
  // convertAzureTimeToMs,
  recordingHasTextStats,
} from '../lib/utils'
import CreateScriptFromRecordingButton from './CreateScriptFromRecordingButton'
import TextStatsSection from './TextStatsSection'
import RecommendationSection from './RecommendationSection'
import {
  getGradeForReadingLevel,
} from '../lib/readingLevel'
import {
  getEvaluationForSpeechRate,
  getRecommendationForSpeechRate,
} from '../lib/speechrate'

const getAverageScores = (
  results: Recording['pronunciation'] = []
): PronunciationScores => {
  const sums: PronunciationScores = {
    PronScore: 0,
    AccuracyScore: 0,
    FluencyScore: 0,
    CompletenessScore: 0,
  }

  for (const result of results) {
    for (const scoreField in result.PronunciationAssessment) {
      sums[scoreField] += result.PronunciationAssessment[scoreField]
    }
  }

  for (const scoreField in sums) {
    sums[scoreField] /= results.length
  }

  return sums
}

const RecordingDetails = ({ recording }: { recording: Recording }) => {
  const pronunciationInfo = useMemo(() => {
    if (recording.pronunciation) {
      return getAverageScores(recording.pronunciation)
    }
    return null
  }, [recording])

  // const lineGraphArousalProps = useMemo<PropsOf<typeof Line>>(() => {
  //   const arousalData = recording.arousal?.time_series
  //   const datasets = []

  //   const datasetCommon = {
  //     borderWidth: 2,
  //     pointRadius: 0,
  //     xAxisID: 'time',
  //   }

  //   if (arousalData) {
  //     datasets.push({
  //       data: arousalData?.map(datum => datum.raw.arousal.high * 100) ?? [],
  //       borderColor: '#ffc000',
  //       backgroundColor: '#FFFBEB',
  //       label: 'Energy levels',
  //       ...datasetCommon,
  //     })
  //   }

  //   const timeSliceSize = arousalData ? arousalData[1].timestamp : 1000

  //   const duration = recording.duration
  //     ? recording.duration * 1000
  //     : arousalData
  //       ? arousalData[arousalData.length - 1].timestamp + timeSliceSize
  //       : 180 * 1000

  //   const numberOfPoints = Math.ceil(duration / timeSliceSize)

  //   return {
  //     data: {
  //       labels: Array.from(
  //         { length: numberOfPoints },
  //         (_, index) => `${index + 1}s`
  //       ),
  //       datasets,
  //     },
  //     options: {
  //       scales: {
  //         y: {
  //           suggestedMin: 0,
  //           suggestedMax: 100,
  //         },
  //       },
  //       plugins: {
  //         tooltip: {
  //           intersect: false,
  //         },
  //       },
  //     } as any,
  //   }
  // }, [recording])

  // const lineGraphPronunciationProps = useMemo<PropsOf<typeof Line>>(() => {
  //   const arousalData = recording.arousal?.time_series
  //   const datasets = []

  //   const datasetCommon = {
  //     borderWidth: 2,
  //     pointRadius: 0,
  //     xAxisID: 'time',
  //   }

  //   const timeSliceSize = arousalData ? arousalData[1].timestamp : 1000

  //   const duration = recording.duration
  //     ? recording.duration * 1000
  //     : arousalData
  //       ? arousalData[arousalData.length - 1].timestamp + timeSliceSize
  //       : 180 * 1000

  //   const numberOfPoints = Math.ceil(duration / timeSliceSize)

  //   if (recording.pronunciation) {
  //     const pronunciationGroupedByTime = Array.from(
  //       { length: numberOfPoints },
  //       () => []
  //     )

  //     recording.pronunciation.forEach(chunk => {
  //       chunk.Words.forEach(word => {
  //         const startPoint = Math.round(
  //           convertAzureTimeToMs(word.Offset) / timeSliceSize
  //         )
  //         pronunciationGroupedByTime[
  //           Math.min(pronunciationGroupedByTime.length - 1, startPoint)
  //         ].push(word.PronunciationAssessment.AccuracyScore)
  //       })
  //     })

  //     const recordingData = pronunciationGroupedByTime.map(
  //       group => group.reduce((acc, curr) => acc + curr, 0) / group.length
  //     )

  //     datasets.push({
  //       data: recordingData,
  //       borderColor: '#5fd764',
  //       backgroundColor: '#ECFDF5',
  //       label: 'Proununciation',
  //       spanGaps: true,
  //       ...datasetCommon,
  //     })
  //   }

  //   return {
  //     data: {
  //       labels: Array.from(
  //         { length: numberOfPoints },
  //         (_, index) => `${index + 1}s`
  //       ),
  //       datasets,
  //     },
  //     options: {
  //       scales: {
  //         y: {
  //           suggestedMin: 0,
  //           suggestedMax: 100,
  //         },
  //       },
  //       plugins: {
  //         tooltip: {
  //           intersect: false,
  //         },
  //       },
  //     } as any,
  //   }
  // }, [recording])

  // const arousalData = useMemo(() => {
  //   if (recording.arousal) {
  //     return recording.arousal.summary.arousal
  //   }
  // }, [recording])

  const hasPronunciation = Boolean(recording.pronunciation)

  const deleteRecording = useDeleteRecording()
  const history = useHistory()

  const handleDelete = () => {
    deleteRecording(recording._id)
    history.push('/recordings')
  }

  const srScore = recording.wordsPerMinute > 230 ? 0 : recording.wordsPerMinute > 200 ? 50 :
    recording.wordsPerMinute > 190 ? 70 : recording.wordsPerMinute > 170 ? 80 :
      recording.wordsPerMinute > 160 ? 90 : recording.wordsPerMinute > 139 ? 100 :
        recording.wordsPerMinute > 129 ? 90 : recording.wordsPerMinute > 119 ? 70 :
          recording.wordsPerMinute > 110 ? 50 : 0
  const srLevel = recording.wordsPerMinute > 230 ? 'Supersonic' : recording.wordsPerMinute > 200 ? 'Very Fast' :
    recording.wordsPerMinute > 190 ? 'Too Fast' : recording.wordsPerMinute > 170 ? 'Fast' :
      recording.wordsPerMinute > 160 ? 'Good' : recording.wordsPerMinute > 139 ? 'Ideal' :
        recording.wordsPerMinute > 129 ? 'Good' : recording.wordsPerMinute > 119 ? 'Moderate' :
          recording.wordsPerMinute > 110 ? 'Slow' : 'Very Slow'


  const readinglevel = clamp((100 - recording.textStats?.reading_index), 0, 100)
  const scScore = readinglevel > 90 ? 90 : readinglevel > 80 ? 90 : readinglevel > 70 ? 90 :
    readinglevel > 60 ? 100 : readinglevel > 50 ? 95 : readinglevel > 30 ? 85 : 80

  // const energyWeightedAverage = ((arousalData.high_fraction * 100 * 0.8) + (arousalData.neutral_fraction * 100 * 0.2)) / (0.8 + 0.2)
  // const energyScore = energyWeightedAverage > 80 ? 0 : energyWeightedAverage > 70 ? 25 : energyWeightedAverage > 60 ? 50 :
  //   energyWeightedAverage > 50 ? 90 : energyWeightedAverage > 45 ? 100 : energyWeightedAverage > 30 ? 90 :
  //     energyWeightedAverage > 20 ? 50 : energyWeightedAverage > 10 ? 25 : 0

  // const nonscriptedOverallWeightedAverage = ((srScore * 0.7) + (energyScore * 0.1) + (scScore * 0.1)) / (0.7 + 0.1 + 0.1)
  const nonscriptedOverallWeightedAverage = ((srScore * 0.7) + (scScore * 0.1)) / (0.7 + 0.1 + 0.1)

  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleOpen = () => {
    setIsOpen(true)
  }

  return (
    <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(6) } }}>
      <div>
        <TranscriptionSection recording={recording} />
      </div>
      {recording.blobUrl ? (
        <RecordingPlayer recording={recording} />
      ) : (
        <Typography sx={{ textAlign: 'center' }}>
          No Recording Available
        </Typography>
      )}

      {hasPronunciation ? (
        <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(4) } }}>
          <Typography sx={{ fontWeight: 800, fontSize: '1.5rem' }}>
            Pronunciation &amp; Energy Analysis
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              rowGap: '4rem',
              columnGap: '2rem',
            }}
          >
            {/* {arousalData ? ( */}
              <>
                <Box gridColumn="1 / -1">
                  <ResultCircle
                    color="#648ddd"
                    title="Overall Score"
                    value={
                      // ((srScore * 0.7) + (energyScore * 0.1) + (scScore * 0.1) + (pronunciationInfo.PronScore * 0.03) + (pronunciationInfo.AccuracyScore * 0.02) + (pronunciationInfo.CompletenessScore * 0.01) + (pronunciationInfo.FluencyScore * 0.04)) / (0.7 + 0.1 + 0.1 + 0.03 + 0.02 + 0.01 + 0.04)
                      ((srScore * 0.7) + (scScore * 0.1) + (pronunciationInfo.PronScore * 0.03) + (pronunciationInfo.AccuracyScore * 0.02) + (pronunciationInfo.CompletenessScore * 0.01) + (pronunciationInfo.FluencyScore * 0.04)) / (0.7 + 0.1 + 0.1 + 0.03 + 0.02 + 0.01 + 0.04)
                    }
                    popupContent={
                      <>
                        <HorizontalBarChart
                          pace={srScore}
                          // energy={arousalData.high_fraction * 100}
                          complexity={clamp(readinglevel, 0, 100)}
                          pronouciation={pronunciationInfo.PronScore}
                          accuracy={pronunciationInfo.AccuracyScore}
                          completeness={pronunciationInfo.CompletenessScore}
                          fluency={pronunciationInfo.FluencyScore}
                        />
                      </>
                    }
                  />
                </Box>
                <Box gridColumn="1 / -1">
                  <ResultCircle
                    color="#ed7d31"
                    title="Speech Pace"
                    value={
                      srScore
                    }
                    popupContent={
                      <Box
                        sx={{
                          '& > * + *': {
                            marginTop: theme => theme.spacing(1)
                          }
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: '800'
                          }}
                        >
                          {srLevel}
                        </Typography>
                        <Typography>Your speech pace is {getEvaluationForSpeechRate(recording.wordsPerMinute)} ({Math.round(recording.wordsPerMinute)}){getRecommendationForSpeechRate(recording.wordsPerMinute)}</Typography>
                      </Box>
                    }
                  />
                </Box>
              </>
             {/* ) : null} */}
            {/* {arousalData ? (
              <ResultCircle
                color="#ffc000"
                title="Energy"
                value={energyScore}
                popupContent={
                  <Box
                    sx={{
                      width: '90%',
                      display: 'block',
                      margin: '0 auto 0',
                    }}
                  >
                    <DoughnutChart
                      low={arousalData.low_fraction * 100}
                      engagement={energyWeightedAverage}
                      silence={arousalData.silence_fraction * 100}
                      no_speech={arousalData.no_speech_fraction * 100}
                    />
                    <Box>
                      <Typography sx={{ fontWeight: 600, color: '#646464' }}>Energy Levels</Typography>
                      <Line {...lineGraphArousalProps} />
                    </Box>
                  </Box>
                }
              />
            ) : null} */}
            <ResultCircle
              color="#fe85ce"
              title="Speech Complexity"
              value={scScore}
              popupContent={
                <>
                  <div>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontWeight: 800,
                        fontSize: '1.25rem',
                        textDecoration: 'underline',
                        marginBottom: '20px'
                      }}
                    >
                      {getGradeForReadingLevel(readinglevel)}
                    </Typography>
                    {recordingHasTextStats(recording) ? (
                      <div>
                        <TextStatsSection recording={recording} />
                      </div>
                    ) : null}
                  </div>
                </>
              }
            />
            <ResultCircle
              color="#5fd764"
              title="Pronunciation"
              value={pronunciationInfo.PronScore}
              popupContent={
                <>
                  <Typography sx={{ textAlign: 'center', marginBottom: '10px' }}>
                    Overall score indicating the pronunciation quality of the
                    given speech.
                  </Typography>
                  {/* <Line {...lineGraphPronunciationProps} /> */}
                </>
              }
            />
            <ResultCircle
              color="#648ddd"
              title="Accuracy"
              value={pronunciationInfo.AccuracyScore}
              popupContent={
                <>
                  <Typography sx={{ textAlign: 'center', marginBottom: '10px' }}>
                    Pronunciation accuracy of the speech. Accuracy indicates how closely the phonemes match a native speaker's pronunciation.
                  </Typography>
                </>
              }
            />
            <ResultCircle
              color="#b664dd"
              title="Completeness"
              value={pronunciationInfo.CompletenessScore}
              popupContent={
                <>
                  <Typography sx={{ textAlign: 'center', marginBottom: '10px' }}>
                    Completeness of the speech, determined by calculating the ratio of pronounced words to reference text input.
                  </Typography>
                </>
              }
            />
            <ResultCircle
              color="#fe85ce"
              title="Fluency"
              value={pronunciationInfo.FluencyScore}
              popupContent={
                <>
                  <Typography sx={{ textAlign: 'center', marginBottom: '10px' }}>
                    Fluency of the given speech. Fluency indicates how closely the speech matches a native speaker's use of silent breaks between words.
                  </Typography>
                </>
              }
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(4) } }}>
          <Typography sx={{ fontWeight: 800, fontSize: '1.5rem' }}>
            Pronunciation &amp; Energy Analysis
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              rowGap: '4rem',
              columnGap: '2rem',
            }}
          >
            {/* {arousalData ? ( */}
              <>
                <Box gridColumn="1 / -1">
                  <ResultCircle
                    color="#648ddd"
                    title="Overall Score"
                    value={
                      nonscriptedOverallWeightedAverage
                    }
                    popupContent={
                      <>
                        <HorizontalBarChartNonScriptMode
                          pace={srScore}
                          // energy={arousalData.high_fraction * 100}
                          complexity={clamp(readinglevel, 0, 100)}
                        />
                      </>
                    }
                  />

                </Box>
                <Box gridColumn="1 / -1">
                  <ResultCircle
                    color="#ed7d31"
                    title="Speech Pace"
                    value={
                      srScore
                    }
                    popupContent={
                      <Box
                        sx={{
                          '& > * + *': {
                            marginTop: theme => theme.spacing(1)
                          }
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontWeight: '800'
                          }}
                        >
                          {srLevel}
                        </Typography>
                        <Typography>Your speech pace is {getEvaluationForSpeechRate(recording.wordsPerMinute)} ({Math.round(recording.wordsPerMinute)}){getRecommendationForSpeechRate(recording.wordsPerMinute)}</Typography>
                      </Box>
                    }
                  />
                </Box>
              </>
            {/* ) : null} */}
            {/* {arousalData ? (
              <ResultCircle
                color="#ffc000"
                title="Energy"
                value={energyScore}
                popupContent={
                  <Box
                    sx={{
                      width: '90%',
                      display: 'block',
                      margin: '0 auto 0',
                    }}
                  >
                    <DoughnutChart
                      low={arousalData.low_fraction * 100}
                      engagement={energyWeightedAverage}
                      silence={arousalData.silence_fraction * 100}
                      no_speech={arousalData.no_speech_fraction * 100}
                    />
                    <Box>
                      <Typography sx={{ fontWeight: 600, color: '#646464' }}>Energy Levels</Typography>
                      <Line {...lineGraphArousalProps} />
                    </Box>
                  </Box>
                }
              />
            ) : null} */}
            <ResultCircle
              color="#fe85ce"
              title="Speech Complexity"
              value={scScore}
              popupContent={
                <>
                  <div>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontWeight: 800,
                        fontSize: '1.25rem',
                        textDecoration: 'underline',
                        marginBottom: '20px'
                      }}
                    >
                      {getGradeForReadingLevel(readinglevel)}
                    </Typography>
                    {recordingHasTextStats(recording) ? (
                      <div>
                        <TextStatsSection recording={recording} />
                      </div>
                    ) : null}
                  </div>
                </>
              }
            />
          </Box>
        </Box>
      )}

      <RecommendationSection recording={recording} />

      <Box sx={{ '&>*+*': { marginTop: theme => theme.spacing(4) } }}>
        <Typography sx={{ fontWeight: 800, fontSize: '1.5rem' }}>
          Options
        </Typography>

        {!hasPronunciation ? (
          <div>
            <CreateScriptFromRecordingButton recording={recording} />
          </div>
        ) : null}

        {recording.script ? (
          <div>
            <Button
              color="green"
              fullWidth
              component={Link}
              to={`/scripts/${recording.script._id}`}
            >
              View Original Script
            </Button>
          </div>
        ) : null}

        <div>
          <Button
            color="error"
            fullWidth
            variant="outlined"
            onClick={() => handleOpen()}
          >
            Delete
          </Button>
        </div>
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogTitle sx={{ textAlign: 'center' }}>Warning!</DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                textAlign: 'center',
              }}
            >
              You will not be able to recover this practice. Are you sure?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleDelete()}
              variant="text"
              sx={{
                backgroundColor: '#8accfc',
                color: 'black'
              }}
            >
              Confirm
            </Button>
            <Button
              onClick={handleClose}
              variant="text"
              sx={{
                backgroundColor: '#d9d9d9',
                color: 'black'
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  )
}

const RecordingByIdPage: React.FC = () => {
  const params = useParams<{ id: string }>()
  const recordingId = params.id

  const recording = useRecordingById(recordingId)

  const isLoading = !recording.data && !recording.error

  return (
    <div>
      <Box display="flex" alignItems="center" justifyItems="space-between">
        <Typography
          component="h1"
          sx={{ fontWeight: 800, color: '#6F96C3', fontSize: '2rem' }}
          mb={2}
        >
          Pitch Details
        </Typography>
      </Box>
      {recording.data ? <RecordingDetails recording={recording.data} /> : null}
      {recording.error ? (
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {recording.error.message}
        </Typography>
      ) : null}
      {isLoading ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}
    </div>
  )
}

export default RecordingByIdPage
