import { initializeApp, FirebaseError } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  getIdToken,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  signInWithPopup,
  signInWithCustomToken,
  GoogleAuthProvider,
} from 'firebase/auth'

import type { NextOrObserver, User } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAeUa9a6i-EE_S_2FdOUZI9Lmr-B29-BDY',
  authDomain: 'pitch-app-dev-6ba66.firebaseapp.com',
  projectId: 'pitch-app-dev-6ba66',
  storageBucket: 'pitch-app-dev-6ba66.appspot.com',
  messagingSenderId: '1028719100251',
  appId: '1:1028719100251:web:f9b238c72a957391431a7e',
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

export const getToken = async (): Promise<string> => {
  return getIdToken(auth.currentUser!)
}

export const getCurrentUser = (): Promise<User | null> => {
  return new Promise(resolve => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    })
  })
}

export const signIn = async (email: string, password: string) => {
  await signInWithEmailAndPassword(auth, email, password)
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  await signInWithPopup(auth, provider)
}

export const signInWithApple = async () => {
  // const provider = new OAuthProvider('apple.com')
}

export const signInWithLinkedIn = async () => {
  window.location.href = process.env.REACT_APP_LINKEDIN_LOGIN_URL
}

export const signInWithToken = async (token: string) => {
  try {
    const credential = await signInWithCustomToken(auth, token)
    return credential.user
  } catch (err) {
    return null
  }
}

export const signUp = async (email: string, password: string) => {
  await createUserWithEmailAndPassword(auth, email, password)
}

export const signOut = async () => {
  return firebaseSignOut(auth)
}

export const onAuthChange = (cb: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, cb)
}

export const resetPassword = async (email: string) => {
  return sendPasswordResetEmail(auth, email)
}

export const isFirebaseError = (error: unknown): error is FirebaseError => {
  return error instanceof FirebaseError
}

export const getMessageForError = (error: FirebaseError): string => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'Email already in use.'
    case 'auth/wrong-password':
      return 'Incorrect password.'
    case 'auth/user-not-found':
      return 'No user exists with that email.'
    case 'auth/weak-password':
      return 'Your password sucks! Use at least 6 characters.'
  }
}
