import React from 'react'
import { Bar } from 'react-chartjs-2'

const HorizontalBarChart = ({
  pace,
  // energy,
  complexity,
}) => {
  const data = {
    // labels: ['Pace', 'Energy', 'Complexity'],
    labels: ['Pace', 'Complexity'],
    datasets: [
      {
        label: 'Score',
        // data: [pace, energy, complexity],
        data: [pace, complexity],
        backgroundColor: [
          '#ed7d31',
          // '#ffc000',
          '#fe85ce',
        ],
        borderColor: [
          '#ed7d31',
          // '#ffc000',
          '#fe85ce',
        ],
        borderWidth: 1
      }
    ]
  }
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
    },
  }

  return (
    <>
      <Bar data={data} options={options} />
    </>
  )
}

export default HorizontalBarChart