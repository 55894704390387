import { useEffect, useState, SyntheticEvent } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useLoggedInRedirect } from '../hooks/use-auth'
import {
  getMessageForError,
  isFirebaseError,
  signInWithGoogle,
  signInWithLinkedIn,
} from '../lib/firebase'
import logo from '../assets/pitchlogo.png'
import { useError } from '../hooks/use-error'

import googleLogo from '../assets/g-logo.png'
import linkedinLogo from '../assets/linkedin-logo.svg'

type CreateHandleEvent = <Event extends SyntheticEvent = SyntheticEvent>(
  fn: (event: Event) => Promise<void>
) => (event: Event) => Promise<void>

const LoginSignup: React.FC<{ mode: 'login' | 'signup' }> = ({ mode }) => {
  const modeDisplayText = mode === 'login' ? 'Login' : 'Sign Up'

  const [loading, setLoading] = useState(false)
  const emailError = useError()

  useEffect(() => {
    emailError.resetError()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, emailError.resetError])

  useLoggedInRedirect()

  const createHandleEvent: CreateHandleEvent = fn => async event => {
    emailError.resetError()
    setLoading(true)

    try {
      await fn(event)
    } catch (err) {
      if (isFirebaseError(err)) {
        emailError.setError(getMessageForError(err))
      }
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleAuth = createHandleEvent(async () => signInWithGoogle())
  const handleLinkedInAuth = createHandleEvent(async () => signInWithLinkedIn())

  return (
    <Box
      sx={{
        maxWidth: '24rem',
        '&>*+*': { marginTop: theme => theme.spacing(4) },
      }}
      pt={8}
    >
      <Box
        component="img"
        display="block"
        mx="auto"
        src={logo}
        alt="logo"
        width="192px"
        height="80px"
        mb={8}
      />

      <Box>
        <Button
          fullWidth
          size="large"
          onClick={handleGoogleAuth}
          disabled={loading}
          variant="outlined"
          startIcon={
            <Box
              component="img"
              borderRadius={1}
              mr={0.5}
              src={googleLogo}
              width="24px"
              height="24px"
              alt=""
            />
          }
        >
          {modeDisplayText} with Google
        </Button>
      </Box>

      <Box textAlign="center">
        <Typography sx={{ fontStyle: 'italic' }}>or</Typography>
      </Box>

      <Box>
        <Button
          fullWidth
          size="large"
          onClick={handleLinkedInAuth}
          disabled={loading}
          variant="outlined"
          startIcon={
            <Box
              borderRadius={1}
              overflow="hidden"
              mr={0.5}
              width="24px"
              height="24px"
              sx={{ backgroundColor: 'white' }}
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={linkedinLogo} alt="" width="24px" height="24px" />
            </Box>
          }
        >
          {modeDisplayText} with LinkedIn
        </Button>
      </Box>
    </Box>
  )
}

export default LoginSignup
