import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'

import { useTokens } from '../hooks/use-tokens'
import { ReactComponent as Coin } from '../assets/bluecoin.svg'
import { CONFIG } from '../config'

const COIN_ZINDEX = 100
const WARNING_MAX = CONFIG.tokensPerPitch * 5

const TokenCount: React.FC = () => {
  const tokens = useTokens()

  const isLoaded = typeof tokens.data === 'number'
  const isLow = isLoaded && tokens.data <= WARNING_MAX

  return (
    <Box
      component={Link}
      to="/settings/tokens"
      display="flex"
      mx="auto"
      maxWidth="12rem"
      alignItems="center"
      justifyContent="center"
      sx={{ textDecoration: 'none' }}
    >
      <Box
        component={Coin}
        sx={{
          zIndex: COIN_ZINDEX,
          display: 'block',
          transform: 'translateY(5px)',
        }}
        width="64px"
        height="64px"
      ></Box>
      <Box
        component="span"
        sx={{
          background: isLow ? '#FEE2E2' : '#E1E1E1',
          borderRadius: '9999px',
          textAlign: 'center',
          zIndex: COIN_ZINDEX - 1,
          fontWeight: 'bold',
          color: isLow ? '#B91C1C' : 'black',
        }}
        ml={-4}
        pl={6}
        py={1}
        pr={4}
      >
        {isLoaded ? tokens.data : '-'}
      </Box>
    </Box>
  )
}

export default TokenCount
